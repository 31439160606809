import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  min-height: 470px;
  flex-direction: column;
  padding: 24px 29px 0 24px;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  @media (min-width: 1100px) {
    padding: 0 30px;
  }
  position: relative;
`;

const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LocationText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #0b1e1d;
  margin: 0;
`;

const SubText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #474747;
  @media (min-width: 1100px) {
    padding-top: 24px;
  }
`;

const Icon = styled.img`
  height: 14px;
  width: 14px;
  @media (min-width: 1100px) {
    display: none;
  }
  cursor: pointer;
`;

const IconM = styled.img`
  height: 40px;
  width: 40px;
  @media (min-width: 1100px) {
    height: 31px;
    width: 33px;
  }
`;

const IconChild = styled.img`
  height: 32px;
  width: 32px;
  margin-left: -3px;
  margin-bottom: 3px;
  @media (min-width: 1100px) {
    height: 24px;
    width: 24px;
    margin-left: -3px;
    margin-bottom: 3px;
  }
`;

const AllStatsLink = styled.a`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #666666;
  cursor: pointer;
  padding: 36px 0px;
  @media (min-width: 1100px) {
    padding: 20px 0px;
  }
  @media (min-width: 1900px) {
    padding: 36px 0px;
  }
`;

const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  letter-spacing: 0.4px;
  width: 326px;
  color: rgba(33, 33, 33, 0.6);
  @media (min-width: 768px) {
    max-width: 353px;
    width: 100%;
    font-size: 10px;
    line-height: 12px;
  }
`;

const StatValue = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #ffffff;
  @media (min-width: 1100px) {
    font-size: 20pxpx;
    line-height: 36px;
  }
`;

const StatText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #ffffff;
  // max-width: 118px;
  text-align: center;
  padding: 0px 10px;
`;

const Grid = styled.div`
  display: grid;
  margin-top: 24px;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
`;

const WideGridItem = styled.div`
  height: 89px;
  width: 326px;
  background-color: #e60000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @media (min-width: 1900px) {
    height: 89px;
    width: 353px;
    padding: 10px 15px;
  }
`;

const GridItem = styled.div`
  height: 135px;
  width: 153px;
  background-color: #e60000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  // @media (min-width: 1100px) {
  //   width: initial;
  //   height: initial;
  //   padding: 9px 15px;
  // }
  @media (min-width: 1900px) {
    height: 130px;
    width: 153px;
    padding: 10px 15px;
  }
`;

const Spacing = styled.div`
  padding-bottom: 24px;
`;

export {
  Text,
  WideGridItem,
  Spacing,
  IconM,
  IconChild,
  StatValue,
  StatText,
  Container,
  GridItem,
  HeaderBox,
  Row,
  LocationText,
  SubText,
  Icon,
  Grid,
  AllStatsLink,
};
