import React, { FC } from 'react';
import { Container, Icon, StartTime, Text } from './LiveDetailsTime.styles';
import { LiveDetailsTimeProps } from './LiveDetailsTime.interface';

export const LiveDetailsTime: FC<LiveDetailsTimeProps> = (props) => {
  const {
    startTime,
    endTime,
    elapsedTime,
    remainingTime,
    startingFrom,
    journeyStatus,
  } = props;
  const end = {
    marginBottom: '22px',
  };
  const elapsed = {
    color: '#ffffff',
    backgroundColor: '#666666',
  };
  const remaining = {
    color: '#ffffff',
    backgroundColor: '#E60000',
  };

  return (
    <Container>
      <StartTime style={{ marginTop: '24px' }}>
        <section style={{ display: 'flex', alignItems: 'center' }}>
          {startingFrom === 'VILLAGE' && (
            <Icon src="/assets/icons/pin_marker.svg" />
          )}
          {startingFrom === 'HEALTHCARE_FACILITY' && (
            <Icon src="/assets/icons/end_hospital.svg" />
          )}
          <Text style={{ marginLeft: '10px' }}>Start</Text>
        </section>
        <>
          <Text>
            {journeyStatus === 'AWAITING_PICKUP'
              ? 'Awaiting pickup'
              : startTime}
          </Text>
        </>
      </StartTime>
      <Icon src="/assets/icons/arrow_down.svg" />
      <StartTime style={elapsed}>
        <section>
          <Text style={{ marginLeft: '10px' }}>Elapsed</Text>
        </section>
        <>
          <Text>{journeyStatus === 'AWAITING_PICKUP' ? '-' : elapsedTime}</Text>
        </>
      </StartTime>
      <Icon src="/assets/icons/arrow_down.svg" />
      <StartTime style={remaining}>
        <section>
          <Text style={{ marginLeft: '10px' }}>Remaining</Text>
        </section>
        <>
          <Text>
            {journeyStatus === 'AWAITING_PICKUP' ? '-' : remainingTime}
          </Text>
        </>
      </StartTime>
      <Icon src="/assets/icons/arrow_down.svg" />
      <StartTime style={end}>
        <section style={{ display: 'flex', alignItems: 'center' }}>
          <Icon src="/assets/icons/end_hospital.svg" />
          <Text style={{ marginLeft: '10px' }}>End</Text>
        </section>
        <>
          <Text>{journeyStatus === 'AWAITING_PICKUP' ? '-' : endTime}</Text>
        </>
      </StartTime>
    </Container>
  );
};
