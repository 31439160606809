import React, { FC } from 'react';
import { LiveDetailsJourneyProps } from './LiveDetailsJourney.interface';
import {
  Container,
  HText,
  Section,
  Text,
  Chip,
} from './LiveDetailsJourney.styles';

export const LiveDetailsJourney: FC<LiveDetailsJourneyProps> = (props) => {
  /* eslint-disable */
  const { origin, destination, vehicle } = props.journey;
  return (
    <Container data-cy="chips_container">
      <Section>
        <HText>Origin</HText>
        <Chip>
          <Text>{origin && origin.length > 0 ? origin : 'NO DATA'}</Text>
        </Chip>
      </Section>
      <Section>
        <HText>Destination</HText>
        <Chip>
          <Text>
            {destination && destination.length > 0 ? destination : 'NO DATA'}
          </Text>
        </Chip>
      </Section>
      <Section>
        <HText>Vehicle</HText>
        <Chip>
          <Text>{vehicle && vehicle.length > 0 ? vehicle : 'NO DATA'}</Text>
        </Chip>
      </Section>
    </Container>
  );
};
