import styled from 'styled-components';

const TopContainer = styled.div`
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #474747;
`;

const SubHeaderText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #0b1e1d;
  margin-top: 10px;
`;

const IconClose = styled.img`
  @media (min-width: 1100px) {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  Row,
  TopContainer,
  HeaderText,
  MainContainer,
  SubHeaderText,
  IconClose,
  Icon,
};
