import styled from 'styled-components';

const DesktopHeader = styled.div`
  display: inline-block;
  display: none;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  padding: 0 19px;
  background: #e60000;
  height: 88px;
  @media (min-width: 1100px) {
    padding: 0 72px;
  }
  @media (min-width: 1100px) {
    display: flex;
  }
`;

const MobileTabHeader = styled.div`
  color: #ffffff;
  justify-content: center;
  align-items: center;
  padding: 0 19px;
  background: #e60000;
  height: 90px;
  display: flex;
  @media (min-width: 1100px) {
    display: none;
  }
`;

const Logo = styled.img`
  height: 42px;
  width: 233px;
`;

const Container = styled.div`
  height: calc(100vh - 90px);
  // height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e60000;
  justify-content: space-around;
  background-size: cover !important;
  // background: url('/assets/images/landing_image.svg') no-repeat center;
  @media (min-width: 1100px) {
    // background: url('/assets/images/landing_image_desktop.svg') no-repeat center;
  }
  @media (min-width: 768px) {
    // background: url('/assets/images/landing_image_tablet.svg') no-repeat center;
  }
`;

const HeaderSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
`;

const HeaderText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #ffffff;
  width: 277px;
  height: 74px;
  text-align: center;
  // margin-top: 24px;
  // margin-left: 19px;
  @media (min-width: 1100px) {
    // margin-left: 72px;
    // margin-top: 45px;
    font-size: 32px;
    line-height: 38px;
    width: 444px;
  }
  @media (min-width: 768px) {
    // margin-top: 45px;
    font-size: 30px;
    line-height: 38px;
    width: 444px;
  }
`;

export {
  DesktopHeader,
  MobileTabHeader,
  Logo,
  Container,
  HeaderSection,
  HeaderText,
};
