import styled from 'styled-components';

const FabButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 24px;
  right: 16px;
  z-index: 1;
  background: #e60000;
  border: none;
  border-radius: 100px;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 16px 20px;
  cursor: pointer;
  @media (min-width: 1100px) {
    display: none;
  }
`;

const StatIcon = styled.img`
  width: 20px;
  height: 18px;
  padding-right: 14px;
`;

const FloatingWindow = styled.div`
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  background: #ffffff;
  @media (min-width: 1100px) {
    display: block;
    top: 55%;
  }
  @media (min-width: 1900px) {
    top: 53%;
    display: block;
  }
`;

const LiveModal = styled.div`
  position: absolute;
  z-index: 1;
  text-align: center;
  top: 90px;
  left: 20px;
  right: 20px;
  max-width: 343px;
  @media (min-width: 768px) {
    top: 120px;
  }
`;

const AlertModal = styled.div`
  position: absolute;
  z-index: 1;
  text-align: center;
  top: 210px;
  left: 20px;
  right: 20px;
  max-width: 343px;
  @media (min-width: 768px) {
    top: 240px;
  }
`;

const InstructionModal = styled.div`
  position: absolute;
  z-index: 1;
  text-align: center;
  top: 150px;
  left: 20px;
  right: 20px;
  max-width: 343px;
  @media (min-width: 768px) {
    top: 180px;
  }
`;

export {
  InstructionModal,
  FloatingWindow,
  StatIcon,
  FabButton,
  LiveModal,
  AlertModal,
};
