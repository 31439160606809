import styled from 'styled-components';

const Container = styled.div`
  align-items: flex-start;
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 16px;
  max-width: 100%;
`;

const HText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.4px;
  color: #474747;
  margin-bottom: 16px;
`;

const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #e60000;
  max-height: 20px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Chip = styled.div`
  padding: 6px 12px;
  border-radius: 68px;
  background: #fef5f5;
  border: 1px solid #e60000;
  box-sizing: border-box;
  margin-right: 7px;
`;

const Flex = styled.div`
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

export { Container, Section, HText, Text, Flex, Chip };
