import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';

import { BrowserRouter } from 'react-router-dom';
import { App } from './app/App';
import { ContextProvider } from './context';

Sentry.init({
  enabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <BrowserRouter>
    <ContextProvider>
      <App />
    </ContextProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
