import styled from 'styled-components';

const Header = styled.div`
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 19px;
  background: #ffffff;
  height: 56px;
  overflow: auto;
  @media (min-width: 768px) {
    height: 88px;
  }
`;

const ScrollButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 60px;
  position: absolute;
  right: 0px;
  top: 0px;
  background: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.66) 103.77%
  );
  @media (min-width: 768px) {
    display: none;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Icon = styled.img`
  height: 16px;
  width: 16px;
  cursor: pointer;
`;

const BackIcon = styled.img`
  height: 16px;
  width: 16px;
  padding: 16px 16px;
  cursor: pointer;
`;

const CountryText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #474747;
  margin-left: 8px;
  padding-right: 24px;
  height: 21.93px;
  cursor: pointer;
`;

const Logo = styled.img`
  height: 21.93px;
  width: 120px;
  padding: 5px 36px;
  cursor: pointer;
`;

const RegionIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-left: 36px;
`;

const Flag = styled.img`
  height: 24px;
  width: 36px;
  margin-left: 36px;
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
  margin-left: 25px;
  @media (max-width: 768px) {
    margin-right: 60px;
  }
`;

const CurrentPathText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #474747;
  padding-right: 36px;
`;

const Text = styled.p`
  margin-left: 10px;
  color: #474747;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  min-width: 100%;
  margin-right: 24px;
`;

const FilterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  width: 100%;
`;

const FilterText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #474747;
  margin-left: 32px;
`;

const Button = styled.button`
  background: #e60000;
  border: none;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 10px;
  width: 90%;
  cursor: pointer;
  margin: 10px 25px;
  @media (min-width: 768px) {
    width: 109px;
    height: 36px;
  }
`;

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 25px;
`;

export {
  Button,
  FilterText,
  FilterSection,
  CurrentPathText,
  CountryText,
  Header,
  Flag,
  Text,
  Filter,
  Flex,
  Icon,
  Logo,
  FilterHeader,
  RegionIcon,
  ScrollButton,
  BackIcon,
};
