import React, { FC } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  GoogleMap,
  KmlLayer,
  LoadScript,
  Marker,
} from '@react-google-maps/api';
import { MapContainer } from './MapCountry.styles';
import { MapCountryProps } from './MapCountry.interface';
import { Loader } from '../Loader';
import { Context } from '../../context';

export const MapCountry: FC<MapCountryProps> = () => {
  const {
    setMiniStats,
    setRegionRoutes,
    setLevel,
    timeFilter,
    setTimeFilter,
    setAlert,
    setAlertText,
    getTime,
    tokenStorage,
    getAccessToken,
    redirectToLandingOnError,
  } = React.useContext(Context);

  const mapStyles = {
    width: '100%',
    height: '100%',
  };

  const mapOptions = {
    disableDefaultUI: true,
    // scrollwheel: false,
  };

  const [googleMap, setGoogleMap] = React.useState(null as any);
  const [loading, setLoading] = React.useState(true);
  const [continentData, setContinentData] = React.useState([] as any);
  const [continentPositions, setContinentPositions] = React.useState([] as any);
  const [kmlArray, setKmlArray] = React.useState([] as any);
  const [totalJourneysStat, setTotalJourneys] = React.useState(0);
  const [statsMini, setStats] = React.useState({} as any);
  const [infoBoxData, setInfoBoxData] = React.useState({} as any);
  const [infoBoxKeys, setInfoBoxKeys] = React.useState([] as any);
  const history = useHistory();

  const accessToken: string = getAccessToken(tokenStorage);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const isMounted = React.useRef(true);

  React.useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  const addMiniStats = (id: any) => {
    if (timeFilter === 'Live') return;
    let miniStatsUrl = '';
    if (
      timeFilter === 'All program' &&
      typeof timeFilter === 'string' &&
      !timeFilter.includes('Last')
    ) {
      miniStatsUrl = `/api/continents/${id}/get_basic_stats/`;
    } else {
      miniStatsUrl = `/api/continents/${id}/get_basic_stats/?start_time=${timeFilter}`;
      if (typeof timeFilter === 'string' && timeFilter.includes('Last')) {
        miniStatsUrl = `/api/continents/${id}/get_basic_stats/?start_time=${getTime(
          timeFilter,
        )}`;
      }
    }
    const miniStats = axios.get(miniStatsUrl, config);
    miniStats
      .then((stats: any) => {
        if (isMounted.current) {
          const statsData = stats.data;
          const totalEmergencies = statsData.total_count;
          const livesSaved = statsData.lives_saved;
          const maternal = statsData.patient_type.MOTHER;
          const neonatal = statsData.patient_type.BABY;
          const both = statsData.patient_type.BOTH;

          const finalData = {
            totalEmergencies,
            livesSaved,
            maternal,
            neonatal,
            both,
          };
          setRegionRoutes({ region: false });
          setStats(finalData);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) redirectToLandingOnError();
      });
  };

  React.useEffect(() => {
    if (totalJourneysStat !== 0) {
      const newObj = { ...statsMini, totalJourneys: totalJourneysStat };
      setMiniStats(newObj);
    }
  }, [statsMini, totalJourneysStat]);

  React.useEffect(() => {
    setLevel({ level: 1 });
  }, []);

  React.useEffect(() => {
    axios
      .get('/api/continents/', config)
      .then((res: any) => {
        if (isMounted.current) {
          axios
            .get(
              `/api/journeys/get_count/?continent_id=${res.data[0].id}`,
              config,
            )
            .then((journeys: any) => {
              if (isMounted.current) {
                const tempJourneys: { [key: string]: any } = {};
                journeys.data.map((journey: any) => {
                  tempJourneys[journey.country_id] = journey;
                  return journey;
                });
                axios
                  .get('/api/mmama_settings/', config)
                  .then((settings: any) => {
                    if (isMounted.current) {
                      settings.data.map((setting: any) => {
                        tempJourneys[setting.country_id].ecd_msg_map =
                          setting.ecd_msg_map;
                        return setting;
                      });
                      setInfoBoxData(tempJourneys);
                      setInfoBoxKeys(Object.keys(tempJourneys));
                    }
                  })
                  .catch((error) => {
                    if (error.response.status === 403)
                      redirectToLandingOnError();
                  });
              }
            })
            .catch((error) => {
              if (error.response.status === 403) redirectToLandingOnError();
            });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) redirectToLandingOnError();
      });
  }, []);

  React.useEffect(() => {
    axios
      .get(`/api/continents/`, config)
      .then((res) => {
        if (isMounted.current) {
          /* eslint-disable no-param-reassign */
          res.data.map((continent: any) => {
            continent.position = {
              lat: continent.lat,
              lng: continent.lon,
            };
            if (
              continent.position.lat === null ||
              continent.position.lng === null
            ) {
              setAlertText("Couldn't fetch location of continent");
              setAlert(true);
            }
            setContinentPositions([...continentPositions, continent.position]);
            return continent;
          });

          const kml = res.data[0].countries.map((k: any) => k);
          setKmlArray(kml);

          setLoading(true);
          const countryPatientCount = res.data.map(
            async (continent: any) =>
              new Promise((resolve, reject) => {
                let dataUrl = '';
                if (timeFilter === 'Live') {
                  dataUrl = `/api/journeys/get_count/?continent_id=${continent.id}&live=true`;
                } else if (
                  timeFilter === 'All program' &&
                  typeof timeFilter === 'string' &&
                  !timeFilter.includes('Last')
                ) {
                  dataUrl = `/api/journeys/get_count/?continent_id=${continent.id}`;
                } else {
                  dataUrl = `/api/journeys/get_count/?continent_id=${continent.id}&start_time=${timeFilter}`;
                  if (
                    typeof timeFilter === 'string' &&
                    timeFilter.includes('Last')
                  ) {
                    dataUrl = `/api/journeys/get_count/?continent_id=${
                      continent.id
                    }&start_time=${getTime(timeFilter)}`;
                  }
                }
                axios
                  .get(dataUrl, config)
                  .then((response: any) => {
                    if (isMounted.current) {
                      resolve(response);
                      const totalJourneys = response.data.reduce(
                        (accumulator: any, currentValue: any) =>
                          accumulator + currentValue.total_count,
                        0,
                      );
                      setTotalJourneys(totalJourneys);
                      setLoading(false);
                    }
                  })
                  .catch((error: any) => {
                    setLoading(false);
                    reject(error);
                    if (error.response.status === 403)
                      redirectToLandingOnError();
                  });
              }),
          );
          Promise.all(countryPatientCount)
            .then((response: any) => {
              if (isMounted.current) {
                const idWIthCount: { id: any; count: any }[] = [];
                response.forEach((promiseResponse: any) => {
                  promiseResponse.data.forEach((country: any) => {
                    idWIthCount.push({
                      id: country.country_id,
                      count: country.total_count,
                    });
                  });
                });
                res.data.forEach((continent: any) => {
                  continent.countries.forEach((country: any) => {
                    const countryData = idWIthCount.find(
                      (cData: any) => cData.id === country.id,
                    );
                    if (countryData) {
                      country.patient_count = countryData.count;
                    }
                  });
                });
                let nonZeroCountries = res.data[0].countries.filter(
                  (country: any) => country.patient_count > -1,
                );
                nonZeroCountries = nonZeroCountries.filter(
                  (country: any) =>
                    country.location_lat !== null ||
                    country.location_lon !== null,
                );
                res.data[0].countries = nonZeroCountries;
                setContinentData(res.data);
                if (timeFilter === 'Live') {
                  let liveCount = 0;
                  res.data[0].countries.forEach((country: any) => {
                    liveCount += country.patient_count;
                  });
                  if (liveCount === 0) {
                    // setAlertText('No Live Cases Found');
                    // setAlert(true);
                    setTimeFilter('All program');
                  }
                }
              }
            })
            .catch((e) => {
              if (e.response.status === 403) redirectToLandingOnError();
              console.log('eee', e);
            });
          addMiniStats(res.data[0].id);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) redirectToLandingOnError();
      });
  }, [timeFilter]);

  const gotoNextLevel = (data: any) => {
    history.push(`/map/${data.id}`);
  };

  const getLabel = (number: number) => ({
    text: number.toLocaleString('en-US'),
    color: 'white',
  });

  const getLabelToolTip = (text: any) => ({
    text: text.toString(),
    color: 'white',
    className: 'tooltip',
    fontSize: '12px',
  });

  const getLocation = (lat: number, lon: number) => {
    if (lat === undefined || lon === undefined) return { lat: 0, lng: 0 };
    return {
      lat,
      lng: lon,
    };
  };

  const iconHistoric = {
    url: '/assets/icons/marker_blue.svg',
    anchor: googleMap !== null ? new google.maps.Point(37, 37) : null,
  };

  const iconLive = {
    url: '/assets/icons/marker_red.svg',
    anchor: googleMap !== null ? new google.maps.Point(37, 37) : null,
  };

  const toolTip = {
    url: '/assets/icons/tooltip.svg',
    anchor: googleMap !== null ? new google.maps.Point(70, 33) : null,
  };

  const onLoading = (map: any) => {
    setLoading(false);
    setGoogleMap(map);
  };

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
  return (
    <>
      <MapContainer data-cy="africa_map">
        {loading === true ? <Loader /> : <></>}
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            center={
              continentPositions && continentPositions.length > 0
                ? continentPositions[0]
                : { lat: -8.2713665, lng: 24.2968758 }
            }
            zoom={window.innerWidth > 768 ? 3.65 : 3}
            mapContainerStyle={mapStyles}
            options={mapOptions}
            onLoad={onLoading}
          >
            {googleMap &&
              continentData &&
              continentData.length > 0 &&
              continentData.map((continent: any) =>
                continent.countries.map((data: any, index: any) => (
                  <div key={data.id} id={`marker_continent_${index}`}>
                    {data.patient_count > 0 && (
                      <Marker
                        icon={timeFilter === 'Live' ? iconLive : iconHistoric}
                        onClick={() => gotoNextLevel(data)}
                        clickable
                        label={getLabel(data.patient_count)}
                        position={getLocation(
                          data.location_lat,
                          data.location_lon,
                        )}
                      />
                    )}
                  </div>
                )),
              )}
            {infoBoxKeys.length > 0 &&
              infoBoxKeys.map(
                (key: string) =>
                  infoBoxData[key].ecd_msg_map && (
                    <Marker
                      label={getLabelToolTip(infoBoxData[key].ecd_msg_map)}
                      icon={toolTip}
                      key={key}
                      position={getLocation(
                        infoBoxData[key].location_lat,
                        infoBoxData[key].location_lon,
                      )}
                    />
                  ),
              )}

            {kmlArray &&
              kmlArray.length > 0 &&
              kmlArray.map((data: any) => (
                <KmlLayer
                  key={`${data.id}_kml`}
                  options={{
                    url: `${window.location.origin}${data.kml_file}`,
                    // url: `https://mama-staging.tivixlabs.com${data.kml_file}`,
                    preserveViewport: true,
                    map: googleMap,
                    clickable: false,
                  }}
                />
              ))}
          </GoogleMap>
        </LoadScript>
      </MapContainer>
    </>
  );
};
