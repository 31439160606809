import React, { FC } from 'react';
import { LiveDetailsPatientProps } from './LiveDetailsPatient.interface';
import {
  Container,
  Section,
  HText,
  Text,
  Chip,
  ChipsContainer,
  Icon,
} from './LiveDetailsPatient.styles';

export const LiveDetailsPatient: FC<LiveDetailsPatientProps> = (props) => {
  /* eslint-disable */
  const {
    patient,
    type,
    symptoms,
    babyType,
    babySymptoms,
    motherType,
    motherSymptoms,
    typeOf,
  } = props.patient;
  const icons = [
    '/assets/icons/pregnant_women_red.svg',
    '/assets/icons/child_red.svg',
  ];
  return (
    <Container data-cy="chips_container">
      <Section>
        <HText>Patient</HText>
        <ChipsContainer>
          {patient &&
            patient.map((p, i) => (
              <Chip key={i}>
                <Icon src={icons[i]} />
                <Text>{p}</Text>
              </Chip>
            ))}
        </ChipsContainer>
      </Section>
      {typeOf !== 'both' ? (
        <>
          <Section>
            <HText>Type</HText>
            <ChipsContainer>
              {type &&
                type.length > 0 &&
                type.map((item: any, index: any) => (
                  <Chip key={index}>
                    <Text>{item}</Text>
                  </Chip>
                ))}
            </ChipsContainer>
          </Section>
          <Section>
            <HText>Symptoms</HText>
            <ChipsContainer>
              {symptoms &&
                symptoms.length > 0 &&
                symptoms.map((item: any, index: any) => (
                  <Chip key={index}>
                    <Text>{item}</Text>
                  </Chip>
                ))}
            </ChipsContainer>
          </Section>
        </>
      ) : (
        <>
          <Section>
            <HText>Type</HText>
            <ChipsContainer>
              {motherType &&
                motherType.length > 0 &&
                motherType.map((item: any, index: any) => (
                  <Chip key={index}>
                    <Text>{item}</Text>
                  </Chip>
                ))}
            </ChipsContainer>
          </Section>
          {/* <Section>
            <HText>Baby Emergency Type</HText>
            <ChipsContainer>
              {babyType &&
                babyType.length > 0 &&
                babyType.map((item: any, index: any) => (
                  <Chip key={index}>
                    <Text>{item}</Text>
                  </Chip>
                ))}
            </ChipsContainer>
          </Section> */}
          <Section>
            <HText>Mother Symptoms</HText>
            <ChipsContainer>
              {motherSymptoms &&
                motherSymptoms.length > 0 &&
                motherSymptoms.map((item: any, index: any) => (
                  <Chip key={index}>
                    <Text>{item}</Text>
                  </Chip>
                ))}
            </ChipsContainer>
          </Section>
          <Section>
            <HText>Baby Symptoms</HText>
            <ChipsContainer>
              {babySymptoms &&
                babySymptoms.length > 0 &&
                babySymptoms.map((item: any, index: any) => (
                  <Chip key={index}>
                    <Text>{item}</Text>
                  </Chip>
                ))}
            </ChipsContainer>
          </Section>
        </>
      )}
    </Container>
  );
};
