import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { isExpired } from 'react-jwt';
import {
  MiniStatContext,
  LiveContext,
  RegionRoutes,
  Level,
} from './context.interface';

export const Context = React.createContext<any>(null);
export const ContextProvider = (props: any) => {
  const history = useHistory();
  const { children } = props;
  const [miniStats, setMiniStats] = React.useState<MiniStatContext>({
    totalEmergencies: 0,
    livesSaved: 0,
    maternal: 0,
    neonatal: 0,
    both: 0,
    totalJourneys: 0,
  });

  React.useEffect(() => {
    document.title = 'm-mama';
  });

  const [isLive, setIsLive] = React.useState<LiveContext>({
    live: false,
  });

  const [liveCases, setLiveCases] = React.useState<any>([] as any);

  const [regionRoutes, setRegionRoutes] = React.useState<RegionRoutes>({
    region: false,
  });

  const [liveDetailsState, setLiveDetailsState] = React.useState<any>({});

  const [level, setLevel] = React.useState<Level>({ level: 1 });

  const [location, setLocation] = React.useState<string>('');

  const DEFAULT_TIME = 'All program'; // this variable needs to be changed inorder to change the default time period period

  const [timeFilter, setTimeFilter] = React.useState<string>(DEFAULT_TIME);

  const DEFAULT_YEAR = 'All Program';

  const [yearOptions, setYearOptions] = React.useState<any>([
    { value: 'All Program', label: 'All Program' },
  ]);

  const [yearFilter, setYearFilter] = React.useState<string>(DEFAULT_YEAR);

  const [countryMini, setCountryMini] = React.useState<string>('');

  const [alert, setAlert] = React.useState<Boolean>(false);

  const [alertText, setAlertText] = React.useState<string>('');

  const [flag, setFlag] = React.useState<string>('');

  const [kmlArray, setKmlArray] = React.useState<any>([] as any);

  const [clockText, setClockText] = React.useState<string>('');

  const [defaultTime, setDefaultTime] = React.useState<boolean>(false);

  const [vehicleInstruction, setVehicleInstruction] =
    React.useState<boolean>(false);

  const [defaultCenter, setDefaultCenter] = React.useState<any>({
    lat: 0,
    lng: 0,
  } as any);

  const [splashAnimation, setSplashAnimation] = React.useState<boolean>(false);

  const [numOfJourneysByFacility, setNumOfJourneysByFacility] =
    React.useState<Number>(0);

  /* eslint-disable */
  const getTime = (time: any) => {
    const d = new Date();
    switch (time) {
      case 'Live': {
        return 'Live';
      }
      case 'Last week': {
        d.setDate(d.getDate() - 7);
        return moment(d).utc().unix();
      }
      case 'Last 1 month': {
        d.setMonth(d.getMonth() - 1);
        return moment(d).utc().unix();
      }
      case 'Last 2 months': {
        d.setMonth(d.getMonth() - 2);
        return moment(d).utc().unix();
      }
      case 'Last quarter': {
        d.setMonth(d.getMonth() - 3);
        return moment(d).utc().unix();
      }
      case 'Last 6 months': {
        d.setMonth(d.getMonth() - 6);
        return moment(d).utc().unix();
      }
      case 'Last 1 year': {
        d.setFullYear(d.getFullYear() - 1);
        return moment(d).utc().unix();
      }
      default: {
        return 'All program';
      }
    }
  };

  React.useEffect(() => {
    setTimeFilter(`${getTime(DEFAULT_TIME)}`);
  }, []);

  const resetYear = () => {
    setYearOptions([{ value: 'All Program', label: 'All Program' }]);
    setYearFilter(DEFAULT_YEAR);
  };

  const getYear = (time: any) => {
    if (time === 'All Program') {
      return {
        startTime: 0,
        endTime: 0,
      };
    } else if (
      !isNaN(time) &&
      Number.isInteger(Number(time)) &&
      time.length == 4 &&
      Number(time) > 2010
    ) {
      return {
        startTime: moment().utc().set('year', time).startOf('year').unix(),
        endTime: moment().utc().set('year', time).endOf('year').unix(),
      };
    }
  };

  React.useEffect(() => {
    setYearFilter(DEFAULT_YEAR);
  }, []);

  const gotoLiveCasesLocation = (path: any) => {
    const locations = path.split('/');
    let country = '';
    let regionDistrict = '';
    let facility = '';
    if (locations.length === 3) {
      country = locations[0];
      regionDistrict = locations[1];
      facility = locations[2];
    } else if (locations.length === 2) {
      country = locations[0];
      regionDistrict = locations[1];
    } else {
      country = locations[0];
    }
    if (liveCases.length > 0) {
      const countryData = liveCases.find(
        (item: any) => item.country_id === country,
      );
      const regionDistrictData = liveCases?.find(
        (item: any) =>
          item.region_id === regionDistrict ||
          item.district_id === regionDistrict,
      );
      const facilityData = liveCases?.find(
        (item: any) => item.end_facility_id === facility,
      );
      if (countryData && regionDistrictData && !facilityData) {
        history.push(`/map/${country}/${regionDistrict}`);
      } else if (countryData && !regionDistrictData && !facilityData) {
        history.push(`/map/${country}`);
      } else {
        history.push(`/map`);
      }
    }
  };

  const tokenStorage = window.localStorage;

  const setTokens = (accessToken: string, storage: Storage): void => {
    storage.setItem('access_token', accessToken);
  };

  const setStatus = (status: string, storage: Storage): void => {
    storage.setItem('status', status);
  };

  const getStatus = (storage: Storage): string => {
    const status = storage.getItem('status');
    return status ? status : 'false';
  };

  const getAccessToken = (storage: Storage): string => {
    const accessToken = storage.getItem('access_token');
    return accessToken ? accessToken : 'NULL';
  };

  // const getRefreshToken = (storage: Storage): string => {
  //   const refreshToken = storage.getItem('refresh_token');
  //   return refreshToken ? refreshToken : 'NULL';
  // };

  const clearStorage = (storage: Storage): void => {
    storage.clear();
  };

  const [isLoggedIn, setIsLoggedIn] = React.useState(
    getStatus(tokenStorage) === 'true' && true,
  );

  const validateToken = () => {
    console.log('validating token...');
    const accessToken = getAccessToken(tokenStorage);
    if (accessToken === 'NULL') {
      setIsLoggedIn(false);
      history.push('/');
    } else {
      if (isExpired(accessToken)) {
        clearStorage(tokenStorage);
        setIsLoggedIn(false);
        history.push('/');
      }
      if (!isExpired(accessToken)) setIsLoggedIn(true);
    }
  };

  const redirectToLandingOnError = () => {
    history.push('/');
  };

  /* eslint-enable */

  React.useEffect(() => {
    let time: any;
    if (alert === true) {
      setTimeout(() => {
        setAlert(false);
        setAlertText('');
      }, 3000);
    }
    return () => {
      clearTimeout(time);
    };
  }, [alert]);

  const listLogoFiles = require
    .context('../../public/assets/animation/', false, /(logo_)/)
    .keys()
    .map((e) => e.substring(2))
    .sort((a, b) => {
      const first = parseInt(a.split('.')[0].split('_')[2], 10);
      const second = parseInt(b.split('.')[0].split('_')[2], 10);
      if (first < second) return -1;
      if (second < first) return 1;
      return 0;
    });

  const [logoUrlList, setLogoUrlList] = React.useState(listLogoFiles);

  const value = {
    clockText,
    setDefaultTime,
    defaultTime,
    setClockText,
    gotoLiveCasesLocation,
    liveCases,
    setLiveCases,
    getTime,
    getYear,
    miniStats,
    setMiniStats,
    kmlArray,
    setKmlArray,
    isLive,
    setIsLive,
    regionRoutes,
    setRegionRoutes,
    level,
    setLevel,
    location,
    setLocation,
    timeFilter,
    setTimeFilter,
    yearOptions,
    setYearOptions,
    yearFilter,
    setYearFilter,
    resetYear,
    liveDetailsState,
    setLiveDetailsState,
    countryMini,
    setCountryMini,
    alert,
    setAlert,
    alertText,
    setAlertText,
    flag,
    setFlag,
    DEFAULT_TIME,
    DEFAULT_YEAR,
    vehicleInstruction,
    setVehicleInstruction,
    defaultCenter,
    setDefaultCenter,
    splashAnimation,
    setSplashAnimation,
    numOfJourneysByFacility,
    setNumOfJourneysByFacility,
    tokenStorage,
    setTokens,
    setStatus,
    getStatus,
    getAccessToken,
    clearStorage,
    isLoggedIn,
    setIsLoggedIn,
    validateToken,
    redirectToLandingOnError,
    logoUrlList,
    setLogoUrlList,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
