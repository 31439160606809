import styled from 'styled-components';

const Container = styled.div`
  min-height: 100vh;
`;

const StatsContainer = styled.div`
  display: flex;
  background: rgba(234, 234, 234, 0.5);
  border-radius: 12px 12px 0px 0px;
  min-height: 100vh;
  padding: 24px 16px;
  align-items: space-between;
  flex-direction: column;
  justify-content: flex-start;
`;

const NumStat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;
  height: 100%;
  margin-top: 10px;
  max-width: 161px;
  padding: 16px 22px;
  @media (max-width: 450px) {
    height: 160px;
  }
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;
`;

const NumData = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #474747;
`;

const DataDesc = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.4px;
  text-align: center;
  color: rgba(33, 33, 33, 0.6);
  width: 110px;
`;

const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  line-height: 15px;
  letter-spacing: 0.4px;
  color: rgba(33, 33, 33, 0.6);
  margin-top: 24px;
`;

const ButtonB = styled.button`
  padding: 6px 16px;
  min-width: 343px;
  height: 36px;
  background: #e60000;
  border-radius: 4px;
  border: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 20px 0;
  @media (min-width: 768px) {
    display: none;
  }
  cursor: pointer;
`;

const ButtonT = styled.button`
  width: 109px;
  height: 36px;
  background: #e60000;
  border-radius: 4px;
  border: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 16px;
  @media (max-width: 767px) {
    display: none;
  }
  cursor: pointer;
`;

const NumStatSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-direction: column;
    margin: 0;
    align-items: center;
    justify-content: space-between;
    flex-wrap: initial;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    width: 100%;
  }
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 20px;
  }
`;

const ChartStatTop = styled.div`
  background: #fff;
  width: 100%;
  min-height: 400px;
  @media (min-width: 768px) {
    margin: 0 10px;
    min-height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const ChartStatBottom = styled.div`
  background: #fff;
  width: 100%;
  min-height: 400px;
  @media (min-width: 768px) {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const ChartStatSection = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  @media (min-width: 768px) {
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ChartHeaderTextNormal = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #474747;
`;

const ChartHeaderTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;

const Logo = styled.img`
  height: 32px;
  width: 177px;
  cursor: pointer;
`;

export {
  MainSection,
  Section,
  ButtonB,
  ButtonT,
  Container,
  StatsContainer,
  NumStat,
  ChartStatTop,
  ChartStatBottom,
  Icon,
  NumData,
  DataDesc,
  Text,
  NumStatSection,
  ChartStatSection,
  ChartHeaderTextNormal,
  ChartHeaderTextContainer,
  // ChartHeaderTextColored,
  TopSection,
  Logo,
};
