import styled from 'styled-components';

const Header = styled.div`
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 19px;
  @media (min-width: 1100px) {
    padding: 0 72px;
    display: none;
  }
  background: #e60000;
  height: 90px;
`;

const DesktopHeader = styled.div`
  display: inline-block;
  display: none;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  padding: 0 19px;
  background: #e60000;
  height: 88px;
  @media (min-width: 1100px) {
    padding: 0 72px;
  }
  @media (min-width: 1100px) {
    display: flex;
  }
`;

const MobileTabHeader = styled.div`
  color: #ffffff;
  justify-content: center;
  align-items: center;
  padding: 0 19px;
  background: #e60000;
  height: 90px;
  display: flex;
  @media (min-width: 1100px) {
    display: none;
  }
`;

const Logo = styled.img`
  height: 42px;
  width: 233px;
`;

const Icon = styled.img`
  height: 36px;
  width: 36px;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
`;

const Container = styled.div`
  height: calc(100vh - 90px);
  // height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e60000;
  justify-content: space-around;
  background-size: cover !important;
  // background: url('/assets/images/landing_image.svg') no-repeat center;
  @media (min-width: 1100px) {
    // background: url('/assets/images/landing_image_desktop.svg') no-repeat center;
  }
  @media (min-width: 768px) {
    // background: url('/assets/images/landing_image_tablet.svg') no-repeat center;
  }
`;

const HeaderButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const HeaderButtonIcon = styled.img`
  height: 18px;
  width: 18px;
`;

const HeaderButtonText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
`;

const BottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;

const Button = styled.button`
  background-color: #e60000;
  border-radius: 4px;
  border: none;
  height: 36px;
  width: 109px;
  margin-top: 12px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ButtonText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  // padding-right: 45px;
  margin-top: 24px;
`;

const HeaderSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
`;

const HeaderText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #ffffff;
  width: 277px;
  height: 74px;
  text-align: center;
  // margin-top: 24px;
  // margin-left: 19px;
  @media (min-width: 1100px) {
    // margin-left: 72px;
    // margin-top: 45px;
    font-size: 32px;
    line-height: 38px;
    width: 444px;
  }
  @media (min-width: 768px) {
    // margin-top: 45px;
    font-size: 30px;
    line-height: 38px;
    width: 444px;
  }
`;

const Tile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 242px;
  width: 176px;
  margin-bottom: 16px;
  cursor: pointer;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 6.875px 20.625px -8.25001px rgba(24, 39, 75, 0.12),
    0px 24.75px 121px -5.5px rgba(24, 39, 75, 0.14),
    0px 11px 38.5px -8.25001px rgba(24, 39, 75, 0.12),
    0px 24.75px 121px -5.5px rgba(24, 39, 75, 0.14);
  border-radius: 11px;
  @media (max-width: 767px) {
    width: 128px;
    height: 176px;
  }
`;

const ContainerAnimation = styled.div`
  // height: 100vh;
`;

const AnimatedIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  @keyframes drop {
    0% {
      transform: translate(-50%, -300%);
    }
    100% {
      transform: translate(-50%, -50%);
    }
  }
  transform: translate(-50%, -50%);
  animation-name: drop;
  animation-duration: 1.3s;
`;

const ContentSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

const DesktopLogOutButton = styled.div`
  display: none;
  align-items: center;
  width: 81px;
  height: 36px;
  position: absolute;
  top: 18px;
  right: 50px;
  cursor: pointer;
  @media (min-width: 770px) {
    display: flex;
  }
`;

const MobileLogOutButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 81px;
  height: 36px;
  cursor: pointer;
  @media (min-width: 770px) {
    display: none;
  }
`;

export {
  Tile,
  AnimatedIcon,
  ContainerAnimation,
  HeaderSection,
  ButtonText,
  Button,
  BottomSection,
  HeaderText,
  Header,
  Logo,
  Icon,
  Container,
  HeaderButton,
  HeaderButtonText,
  HeaderButtonIcon,
  DesktopHeader,
  MobileTabHeader,
  ContentSection,
  DesktopLogOutButton,
  MobileLogOutButton,
};
