import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const HText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.4px;
  color: #474747;
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #e60000;
`;

const Chip = styled.div`
  padding: 6px 12px;
  border-radius: 68px;
  background: #fef5f5;
  border: 1px solid #e60000;
  box-sizing: border-box;
  margin-right: 7px;
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

export { Container, Section, HText, Text, Chip, ChipsContainer, Icon };
