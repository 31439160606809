import React, { FC } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { MapContainer } from './MapFacility.styles';
import { MapFacilityProps } from './MapFacility.interface';
import { Loader } from '../Loader';
import { Context } from '../../context';

export const MapFacility: FC<MapFacilityProps> = (props) => {
  const history = useHistory();

  const {
    setRegionRoutes,
    setLevel,
    setLocation,
    timeFilter,
    setAlert,
    setAlertText,
    gotoLiveCasesLocation,
    setMiniStats,
    getTime,
    setNumOfJourneysByFacility,
    tokenStorage,
    getAccessToken,
    redirectToLandingOnError,
    // currentCountry,
  } = React.useContext(Context);
  const [googleMap, setGoogleMap] = React.useState(null as any);

  const accessToken: string = getAccessToken(tokenStorage);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const mapStyles = {
    width: '100%',
    height: '100%',
  };

  const mapOptions = {
    disableDefaultUI: true,
    // scrollwheel: false,
  };

  const { match } = props;
  const { url } = match;
  const urls = url.split('/');
  const id = urls[urls.length - 1];
  const [healthFacilities, setHealthFacilities] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalJourneysStat, setTotalJourneys] = React.useState(0);
  const [statsMini, setStats] = React.useState({} as any);

  const isMounted = React.useRef(true);

  React.useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  React.useEffect(() => {
    setLocation(history.location.pathname);
  }, [history.location.pathname]);

  React.useEffect(() => {
    setLevel({ level: 3 });
  }, []);

  const addMiniStats = (urlid: any) => {
    if (timeFilter === 'Live') return;
    let miniStatsUrl = '';
    if (
      timeFilter === 'All program' &&
      typeof timeFilter === 'string' &&
      !timeFilter.includes('Last')
    ) {
      miniStatsUrl = `/api/regions/${urlid}/get_basic_stats/`;
    } else {
      miniStatsUrl = `/api/regions/${urlid}/get_basic_stats/?start_time=${timeFilter}`;
      if (typeof timeFilter === 'string' && timeFilter.includes('Last')) {
        miniStatsUrl = `/api/regions/${urlid}/get_basic_stats/?start_time=${getTime(
          timeFilter,
        )}`;
      }
    }
    const miniStats = axios.get(miniStatsUrl, config);
    miniStats
      .then((stats: any) => {
        if (isMounted.current) {
          const statsData = stats.data;
          const totalEmergencies = statsData.total_count;
          const livesSaved = statsData.lives_saved;
          const maternal = statsData.patient_type.MOTHER;
          const neonatal = statsData.patient_type.BABY;
          const both = statsData.patient_type.BOTH;

          const finalData = {
            totalEmergencies,
            livesSaved,
            maternal,
            neonatal,
            both,
          };
          setRegionRoutes({ region: false });
          setStats(finalData);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) redirectToLandingOnError();
      });
  };

  React.useEffect(() => {
    if (totalJourneysStat !== 0) {
      const newObj = { ...statsMini, totalJourneys: totalJourneysStat };
      setMiniStats(newObj);
    }
  }, [statsMini, totalJourneysStat]);

  React.useEffect(() => {
    // let count = 0;
    setIsLoading(true);
    addMiniStats(id);
    setRegionRoutes({ region: true });
    let dataUrlRegion = '';
    let dataUrlDistrict = '';
    if (timeFilter === 'Live') {
      dataUrlRegion = `/api/journeys/get_count/?region_id=${id}&live=true`;
      dataUrlDistrict = `/api/journeys/get_count/?district_id=${id}&live=true`;
    } else if (
      timeFilter === 'All program' &&
      typeof timeFilter === 'string' &&
      !timeFilter.includes('Last')
    ) {
      dataUrlRegion = `/api/journeys/get_count/?region_id=${id}`;
      dataUrlDistrict = `/api/journeys/get_count/?district_id=${id}`;
    } else {
      dataUrlRegion = `/api/journeys/get_count/?region_id=${id}&start_time=${timeFilter}`;
      dataUrlDistrict = `/api/journeys/get_count/?district_id=${id}&start_time=${timeFilter}`;
      if (typeof timeFilter === 'string' && timeFilter.includes('Last')) {
        dataUrlRegion = `/api/journeys/get_count/?region_id=${id}&start_time=${getTime(
          timeFilter,
        )}`;
        dataUrlDistrict = `/api/journeys/get_count/?district_id=${id}&start_time=${getTime(
          timeFilter,
        )}`;
      }
    }
    const region = axios.get(dataUrlRegion, config);
    const district = axios.get(dataUrlDistrict, config);
    region
      .then((res: any) => {
        if (isMounted.current) {
          setIsLoading(false);
          const totalJourneys = res.data.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + currentValue.total_count,
            0,
          );
          setTotalJourneys(totalJourneys);
          if (res.data.length < 1) {
            if (timeFilter === 'Live') {
              // setAlertText('No Live Case Found');
              // setAlert(true);
              gotoLiveCasesLocation(history.location.pathname.slice(5));
            } else {
              setAlertText('No Cases Found');
              setAlert(true);
            }
          } else {
            const noneZeroHealthFacilities = res.data.filter(
              (facility: any) =>
                facility.total_count > 0 &&
                (facility.location_lat !== null ||
                  facility.location_lon !== null),
            );
            if (noneZeroHealthFacilities.length > 0) {
              setHealthFacilities(noneZeroHealthFacilities);
            } else if (timeFilter === 'Live') {
              gotoLiveCasesLocation(history.location.pathname.slice(5));
            } else {
              setAlertText("Couldn't fetch facility location");
              setAlert(true);
            }
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) redirectToLandingOnError();
        district
          .then((resp: any) => {
            if (isMounted.current) {
              setIsLoading(false);
              const totalJourneys = resp.data.reduce(
                (accumulator: any, currentValue: any) =>
                  accumulator + currentValue.total_count,
                0,
              );
              setTotalJourneys(totalJourneys);
              let validData = [] as any;
              if (resp.data.length > 0) {
                validData = resp.data.filter(
                  (ele: any) =>
                    (ele.location_lat !== null || ele.location_lon !== null) &&
                    ele.total_count > 0,
                );
                if (validData.length > 0) {
                  setHealthFacilities(validData);
                } else if (timeFilter === 'Live') {
                  gotoLiveCasesLocation(history.location.pathname.slice(5));
                } else {
                  setAlertText("Couldn't fetch facility location");
                  setAlert(true);
                }
              } else {
                // eslint-disable-next-line no-lonely-if
                if (timeFilter === 'Live') {
                  // setAlertText('No Live Case Found');
                  // setAlert(true);
                  gotoLiveCasesLocation(history.location.pathname.slice(5));
                } else {
                  setAlertText('No Cases Found');
                  setAlert(true);
                }
              }
            }
          })
          .catch((e: any) => {
            setIsLoading(false);
            if (e.response.status === 403) redirectToLandingOnError();
            history.push('/pagenotfound');
          });
      });
  }, [url, timeFilter]);

  const getLocation = (lat: number, lng: number) => {
    if (lat && lng) {
      return {
        lat,
        lng,
      };
    }
    return { lat: 0, lng: 0 };
  };

  React.useEffect((): any => {
    const locationss: any[] = [];
    healthFacilities.forEach((facility: any) => {
      locationss.push({
        lat: facility.location_lat,
        lng: facility.location_lon,
      });
    });
  }, [healthFacilities]);

  const getLabel = (count: number) => {
    if (count) {
      return { text: count.toLocaleString('en-US'), color: '#fff' };
    }
    return '';
  };

  const clickHandler = (facilityCentre: any) => {
    setNumOfJourneysByFacility(facilityCentre.total_count);
    history.push(`${match.url}/${facilityCentre.healthcare_facility_id}`);
  };

  const iconHistoric = {
    url: '/assets/icons/marker_blue.svg',
    anchor: googleMap !== null ? new google.maps.Point(37, 37) : null,
  };

  const iconLive = {
    url: '/assets/icons/marker_red.svg',
    anchor: googleMap !== null ? new google.maps.Point(37, 37) : null,
  };

  /* added below to fitbounds */
  React.useEffect(() => {
    if (healthFacilities.length > 0 && googleMap !== null) {
      const bounds = new window.google.maps.LatLngBounds();
      healthFacilities.forEach((marker: any) => {
        bounds.extend(
          new window.google.maps.LatLng(
            marker.location_lat,
            marker.location_lon,
          ),
        );
      });
      googleMap.fitBounds(bounds);
      if (healthFacilities.length === 1) {
        let zoomLevel = 11;
        if (window.innerWidth > 768) zoomLevel = 12;
        googleMap.setZoom(zoomLevel);
      } else {
        googleMap.setZoom(googleMap.zoom);
      }
    }
  }, [healthFacilities]);

  const onLoading = (map: any) => {
    setIsLoading(false);
    setGoogleMap(map);
  };

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
  return (
    <>
      <MapContainer data-cy="region_map">
        {isLoading && <Loader />}
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            // center={centerLocation}
            mapContainerStyle={mapStyles}
            options={mapOptions}
            onLoad={onLoading}
          >
            {healthFacilities &&
              healthFacilities.length > 0 &&
              healthFacilities.map((facility: any, index: any) => (
                <div id={`marker_facility_${index}`} key={facility.name}>
                  <Marker
                    icon={timeFilter === 'Live' ? iconLive : iconHistoric}
                    onClick={() => {
                      clickHandler(facility);
                    }}
                    position={getLocation(
                      facility.location_lat,
                      facility.location_lon,
                    )}
                    label={getLabel(facility.total_count)}
                  />
                </div>
              ))}
          </GoogleMap>
        </LoadScript>
      </MapContainer>
    </>
  );
};
