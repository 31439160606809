import React, { FC } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import { Context } from '../../context';
import {
  TopContainer,
  SubHeaderText,
  HeaderText,
  IconClose,
  MainContainer,
  Icon,
  Row,
} from './LiveDetails.styles';
import { LiveDetailsTime } from '../LiveDetailsTime';
import { LiveDetailsPatient } from '../LiveDetailsPatient';
import { LiveDetailsJourney } from '../LiveDetailsJourney';
import { LiveDetailsProps } from './LiveDetails.interface';

export const LiveDetails: FC<LiveDetailsProps> = (props) => {
  const { liveDetailsState } = React.useContext(Context);
  const { toggle } = props;

  const flex = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const [tab, setTab] = React.useState(0);
  // const [time, setTime] = React.useState({} as any);
  const [startend, setStartend] = React.useState({} as any);
  const [patient, setPatient] = React.useState({} as any);
  const [journey, setJourney] = React.useState({} as any);

  const handleChange = (event: any, value: React.SetStateAction<number>) => {
    setTab(value);
  };

  const calculateTime = () => {
    const tempData = liveDetailsState;
    const timeToComplete = tempData?.live?.duration_seconds;
    const timeToCompleteMinutes = Math.floor(timeToComplete / 60);
    const unixTime = tempData?.live?.configured_at_unix;
    const createdAt = moment.unix(unixTime).local().format('llll');
    const now = moment().local().format('llll');
    const isFuture = moment(createdAt).isAfter(now);
    const estimatedTimeToFinish = moment(createdAt)
      .add(timeToCompleteMinutes, 'minutes')
      .format('llll');
    const start = moment(createdAt).format('llll');
    const end = moment(estimatedTimeToFinish).format('llll');
    const duration = moment.duration(moment(end).diff(moment(start)));
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    let durationString = '';
    if (hours > 0) {
      durationString = `${hours}:${minutes}`;
      if (minutes < 10) {
        durationString = `${hours}:0${minutes}`;
        if (hours < 10) {
          durationString = `0${hours}:0${minutes}`;
        }
      }
      if (hours < 10) {
        durationString = `0${hours}:${minutes}`;
        if (minutes < 10) {
          durationString = `0${hours}:0${minutes}`;
        }
      }
    } else if (minutes > 0) {
      durationString = `00:${minutes}`;
      if (minutes < 10) {
        durationString = `00:0${minutes}`;
      }
    } else {
      durationString = `${seconds}s`;
    }
    const currentTime = moment().format('llll');
    let timeDifference = moment(estimatedTimeToFinish).diff(
      moment(currentTime),
      'minutes',
    );
    if (timeDifference < 0) {
      timeDifference = 0;
    }
    const elapsedT = moment(currentTime).diff(moment(createdAt), 'minutes');
    const remainingT = timeDifference;
    if (isFuture === true) {
      return {
        remaining: `00:00`,
        elapsed: `00:00`,
      };
    }
    if (remainingT === 0) {
      return {
        remaining: `00:00`,
        elapsed: `${durationString}`,
      };
    }
    return {
      remaining: `${moment()
        .local()
        .startOf('day')
        .add({ minutes: remainingT })
        .format('HH:mm')}`,
      elapsed: `${moment()
        .local()
        .startOf('day')
        .add({ minutes: elapsedT })
        .format('HH:mm')}`,
    };
  };

  /* eslint-disable */
  React.useEffect(() => {
    const timeToComplete = liveDetailsState?.live?.duration_seconds;
    const timeToCompleteMinutes = Math.floor(timeToComplete / 60);
    const unixTime = liveDetailsState?.live?.configured_at_unix;
    const createdAt = moment.unix(unixTime).local().format('llll');
    const estimatedTimeToFinish = moment(createdAt)
      .add(timeToCompleteMinutes, 'minutes')
      .format('llll');
    setStartend({
      start: moment(createdAt).format('hh:mma'),
      end: moment(estimatedTimeToFinish).format('hh:mma'),
    });
  }, [liveDetailsState]);

  React.useEffect(() => {
    const tempData = liveDetailsState;
    let patientData = {};
    if (tempData?.live?.case_type === 'MOTHER') {
      patientData = {
        type: tempData?.live?.mother_emergency_types,
        symptoms: tempData?.live?.mother_symptoms,
        patient: [tempData?.live?.case_type],
        typeOf: 'mother',
      };
    } else if (tempData?.live?.case_type === 'BABY') {
      patientData = {
        type: tempData?.live?.baby_emergency_types,
        symptoms: tempData?.live?.baby_symptoms,
        patient: [tempData?.live?.case_type],
        typeOf: 'baby',
      };
    } else if (tempData?.live?.case_type === 'BOTH') {
      patientData = {
        // babyType: tempData?.live?.baby_emergency_types,
        babySymptoms: tempData?.live?.baby_symptoms,
        motherType: tempData?.live?.mother_emergency_types,
        motherSymptoms: tempData?.live?.mother_symptoms,
        patient: ['Mother', 'Baby'],
        typeOf: 'both',
      };
    }
    setPatient(patientData);
  }, [liveDetailsState]);

  const checkVehicle = (data: any) => {
    if (data.ambulance_id === null && data.driver_id === null) {
      return 'NOT ASSIGNED';
    } else if (data.ambulance_id === null) {
      return 'Community Driver';
    }
    return 'Ambulance';
  };

  React.useEffect(() => {
    const tempData = liveDetailsState;

    if (tempData?.live?.start_type === 'HEALTHCARE_FACILITY') {
      setJourney({
        origin: tempData.live.start_facility_name,
        destination: tempData.live.end_facility_name,
        vehicle: checkVehicle(tempData.live),
      });
    } else if (tempData?.live?.start_type === 'VILLAGE') {
      setJourney({
        origin: tempData?.live?.start_village_name,
        destination: tempData.live.end_facility_name,
        vehicle: checkVehicle(tempData.live),
      });
    }
  }, [liveDetailsState]);

  return (
    <>
      <MainContainer data-cy="emergency_details">
        <TopContainer>
          <section style={flex}>
            <HeaderText>emergency details</HeaderText>
            <IconClose onClick={() => toggle()} src="/assets/icons/cross.svg" />
          </section>
          <SubHeaderText>
            {liveDetailsState?.live?.end_facility_name}
          </SubHeaderText>
        </TopContainer>
        <section>
          <Tabs variant="fullWidth" value={tab} onChange={handleChange}>
            <Tab
              label={
                <Row>
                  <Icon src="/assets/icons/time.svg" />
                  Time
                </Row>
              }
            />
            <Tab
              label={
                <Row>
                  <Icon src="/assets/icons/patient.svg" />
                  Patient
                </Row>
              }
            />
            <Tab
              label={
                <Row>
                  <Icon src="/assets/icons/local_taxi.svg" />
                  Trip
                </Row>
              }
            />
          </Tabs>
        </section>
        <section>
          {tab === 0 && (
            <LiveDetailsTime
              startTime={startend.start}
              endTime={startend.end}
              elapsedTime={calculateTime().elapsed}
              remainingTime={calculateTime().remaining}
              startingFrom={liveDetailsState?.live?.start_type}
              journeyStatus={liveDetailsState?.journey_status}
            />
          )}
          {tab === 1 && <LiveDetailsPatient patient={patient} />}
          {tab === 2 && <LiveDetailsJourney journey={journey} />}
        </section>
      </MainContainer>
    </>
  );
};
