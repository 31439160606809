import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  height: 52px;
  padding: 0px 16px;
  background: #666666;
  opacity: 0.8;
  box-shadow: 0px 3px 4.65px rgba(0, 0, 0, 0.27);
  border-radius: 3px;
`;

const Text = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #e8e8e8;
`;

const TextButton = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  color: #fef5f5;
`;

export { Container, Text, TextButton };
