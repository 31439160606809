import React, { FC } from 'react';
import { Container, TextButton, Text } from './LiveAlert.styles';
import { Context } from '../../context';

export const LiveAlert: FC<any> = ({ textProp, btnText, action, button }) => {
  const {
    setTimeFilter,
    timeFilter,
    setVehicleInstruction,
    getTime,
    setDefaultTime,
  } = React.useContext(Context);

  const handleClick = () => {
    if (action === 'Live') {
      setTimeFilter(action);
    } else {
      const timeStamp = getTime(action);
      setDefaultTime(true);
      setTimeFilter(timeStamp);
    }
  };

  const handleCross = () => {
    setVehicleInstruction(false);
  };

  return (
    <>
      {button === false || button === undefined || button === null ? (
        <Container
          style={
            timeFilter === 'Live' ? { background: 'rgba(0, 0, 230, 0.8)' } : {}
          }
          data-cy="live_emergencies"
          onClick={handleClick}
        >
          <Text>{textProp}</Text>
          <TextButton>{btnText}</TextButton>
        </Container>
      ) : (
        <Container
          style={{
            background: '#666666',
            boxShadow: '0px 3px 4.65px rgba(0, 0, 0, 0.27)',
            borderRadius: '3px',
          }}
          onClick={handleCross}
        >
          <Text>{textProp}</Text>
          <img src="/assets/icons/cross_white.svg" alt="button" />
        </Container>
      )}
    </>
  );
};
