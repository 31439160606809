import React, { FC } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Drawer, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { Context } from '../../context';
import {
  Header,
  Flex,
  Icon,
  Logo,
  Filter,
  Text,
  Flag,
  CountryText,
  CurrentPathText,
  FilterText,
  FilterSection,
  Button,
  FilterHeader,
  RegionIcon,
  ScrollButton,
  BackIcon,
} from './TopNavbar.styles';

import { TopNavbarProps } from './TopNavbar.interface';

export const TopNavbar: FC<TopNavbarProps> = (props) => {
  const history = useHistory();
  const [country, setCountry] = React.useState([]);
  const [region, setRegion] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [selectedRegion, setSelectedRegion] = React.useState('');
  const [countryName, setCountryName] = React.useState({
    name: '',
    id: '',
    flag: '',
    kml: '',
  });
  const [facilityName, setFacilityName] = React.useState('');
  const [regionName, setRegionName] = React.useState({ name: '', id: '' });
  const [filterShow, setFilterShow] = React.useState(false);
  const contextData = React.useContext(Context);
  const { location } = contextData;
  const { DEFAULT_TIME } = contextData;
  const { numOfJourneysByFacility } = contextData;
  const { tokenStorage, getAccessToken, redirectToLandingOnError } =
    contextData;
  const [selectedTime, setSelectedTime] = React.useState(DEFAULT_TIME);
  const { yearFilter, setYearFilter } = React.useContext(Context);
  const [clockTextHeader, setClockTextHeader] = React.useState(DEFAULT_TIME);
  const { yearOptions } = React.useContext(Context);
  const timeOptions = [
    { value: 'Live', label: 'Live' },
    { value: 'Last week', label: 'Last week' },
    { value: 'Last 1 month', label: 'Last 1 month' },
    { value: 'Last 2 months', label: 'Last 2 months' },
    { value: 'Last quarter', label: 'Last quarter' },
    { value: 'Last 6 months', label: 'Last 6 months' },
    { value: 'Last 1 year', label: 'Last 1 year' },
  ];
  const { level, currentPath } = props;

  const accessToken: string = getAccessToken(tokenStorage);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  React.useEffect(() => {
    if (contextData.clockText) {
      setClockTextHeader(contextData.clockText);
    }
  }, []);

  const reset = () => {
    if (level === 1) {
      setSelectedCountry('');
      setSelectedRegion('');
      setSelectedTime(clockTextHeader);
      return;
    }
    const path = contextData.location.slice(5);
    const pdata = path.split('/');
    if (pdata.length > 0) {
      if (pdata.length > 1) {
        const regionID = pdata[1];
        setSelectedRegion(regionID);
      }
      const countryID = pdata[0];
      setSelectedCountry(countryID);
      setSelectedTime(clockTextHeader);
    }
  };

  React.useEffect(() => {
    if (contextData.defaultTime === true) {
      contextData.setDefaultTime(false);
      setClockTextHeader(DEFAULT_TIME);
    }
  }, [contextData.defaultTime]);

  const goBack = () => {
    if (level === 1) {
      history.push('/');
      return;
    }
    if (level === 2) {
      history.push('/map');
      return;
    }
    if (level === 3) {
      const countryId = location.split('/')[2];
      history.push(`/map/${countryId}`);
      return;
    }
    if (level === 4) {
      const countryId = location.split('/')[2];
      const regionId = location.split('/')[3];
      history.push(`/map/${countryId}/${regionId}`);
      return;
    }
    history.goBack();
  };

  const useStyles = makeStyles(() =>
    createStyles({
      formControl: {
        width: '90%',
        margin: '10px 25px',
      },
    }),
  );

  React.useEffect(() => {
    if (contextData.timeFilter === 'All program') {
      setClockTextHeader('All program');
    } else if (contextData.timeFilter === 'Live') {
      setClockTextHeader('Live');
    } else {
      // do nothing
    }
  }, [contextData.timeFilter]);

  const checkLive = () => {
    axios
      .get(`/api/journeys/?live=true`, config)
      .then((response) => {
        if (response.data.length > 0) {
          contextData.setLiveCases(response.data);
          contextData.setIsLive({ live: true });
        } else {
          contextData.setIsLive({ live: false });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) redirectToLandingOnError();
      });
  };

  const toggleFilter = () => {
    setFilterShow(!filterShow);
  };

  React.useEffect(() => {
    checkLive();
    const time = setInterval(() => {
      checkLive();
    }, 5000);
    return () => {
      clearInterval(time);
    };
  }, []);

  const setHeaders = () => {
    // used this to add labels in header
    if (level && level > 1) {
      const path = contextData.location.slice(5);
      const pdata = path.split('/');
      if (pdata.length > 0) {
        const countryID = pdata[0];
        if (pdata.length > 1) {
          let resultRegion = {} as any;
          const regionID = pdata[1];
          resultRegion = region.find((item: any) => item.id === regionID);
          setRegionName(resultRegion);
          if (resultRegion === undefined) {
            axios
              .get(`/api/regions/${regionID}/`, config)
              .then((res) => {
                setRegionName(res.data);
              })
              .catch((err) => {
                if (err.response.status === 403) redirectToLandingOnError();
                console.log(err);
              });
          }
        }
        if (pdata.length > 2) {
          let resultFacility = {} as any;
          const facilityID = pdata[2];
          axios
            .get(`/api/healthcare_facilities/${facilityID}/`, config)
            .then((res) => {
              resultFacility = res.data;
              setFacilityName(resultFacility.name);
            })
            .catch((err) => {
              if (err.response.status === 403) redirectToLandingOnError();
              console.log(err);
            });
        }
        let resultCountry = {} as any;
        resultCountry = country.find((item: any) => item.id === countryID);
        // axios.get(`/api/regions/${resultCountry?.id}`).then((res) => {
        //   const latlon = { lat: res.data.location_lat, lng: res.data.location_lon };
        //   contextData.setDefaultCenter(latlon);
        // });
        setCountryName(resultCountry);
        if (level === 2) {
          contextData.setCountryMini(resultCountry);
          return;
        }
      }
    }
    if (level === 1) {
      contextData.setCountryMini({ name: 'Africa' }); // setting this as africa to show africa in mini stats
    }
  };

  React.useEffect(() => {
    if (level === 3) {
      contextData.setCountryMini({ name: regionName?.name });
    }
  }, [level, contextData.location, regionName]);

  React.useEffect(() => {
    setHeaders();
  }, [level, location, country, region]);

  React.useEffect(() => {
    if (level === 1) {
      setSelectedRegion('');
      setSelectedCountry('');
      return;
    }
    reset();
  }, [location, level]);

  const filterApply = () => {
    /* eslint-disable */
    let d = new Date();
    let time = 1;
    // 1 is null, 0 is live
    const prevTimeFilter = contextData.timeFilter;
    switch (selectedTime) {
      case 'Live':
        time = 0;
        break;
      case 'Last week':
        d.setDate(d.getDate() - 7);
        time = moment(d).utc().unix();
        break;
      case 'Last 1 month':
        d.setMonth(d.getMonth() - 1);
        time = moment(d).utc().unix();
        break;
      case 'Last 2 months':
        d.setMonth(d.getMonth() - 2);
        time = moment(d).utc().unix();
        break;
      case 'Last quarter':
        d.setMonth(d.getMonth() - 3);
        time = moment(d).utc().unix();
        break;
      case 'Last 6 months':
        d.setMonth(d.getMonth() - 6);
        time = moment(d).utc().unix();
        break;
      case 'Last 1 year':
        d.setFullYear(d.getFullYear() - 1);
        time = moment(d).utc().unix();
        break;
      default:
        time = 9;
    }
    /* eslint-enable  */
    if (selectedCountry && selectedRegion) {
      let dataUrlRegion = '';
      let dataUrlDistrict = '';
      if (time !== 1) {
        if (time === 0) {
          dataUrlRegion = `/api/journeys/get_count/?region_id=${selectedRegion}&live=true`;
          dataUrlDistrict = `/api/journeys/get_count/?district_id=${selectedRegion}&live=true`;
        } else {
          dataUrlRegion = `/api/journeys/get_count/?region_id=${selectedRegion}&start_time=${time}`;
          dataUrlDistrict = `/api/journeys/get_count/?district_id=${selectedRegion}&start_time=${time}`;
        }
      } else {
        dataUrlRegion = `/api/journeys/get_count/?region_id=${selectedRegion}`;
        dataUrlDistrict = `/api/journeys/get_count/?district_id=${selectedRegion}`;
      }
      const regionUrl = axios.get(dataUrlRegion, config);
      const district = axios.get(dataUrlDistrict, config);
      regionUrl
        .then((res: any) => {
          if (res.data.length < 1) {
            contextData.setAlertText('No results, Please adjust the filters');
            contextData.setAlert(true);
            contextData.setTimeFilter(prevTimeFilter);
            reset();
            toggleFilter();
            return;
          }
          if (time === 0) {
            contextData.setTimeFilter('Live');
          } else if (time === 9) {
            contextData.setTimeFilter('All program');
          } else {
            contextData.setTimeFilter(time);
          }
          setClockTextHeader(selectedTime);
          toggleFilter();
          history.push(`/map/${selectedCountry}/${selectedRegion}`);
        })
        .catch((error) => {
          if (error.response.status === 403) redirectToLandingOnError();
          district
            .then((resp: any) => {
              let count = 0;
              resp.data.forEach((item: any) => {
                if (item.total_count > 0) {
                  count += 1;
                }
              });
              if (count < 1) {
                contextData.setAlertText(
                  'No results, Please adjust the filters',
                );
                contextData.setAlert(true);
                contextData.setTimeFilter(prevTimeFilter);
                toggleFilter();
                reset();
                return;
              }
              if (time === 0) {
                contextData.setTimeFilter('Live');
              } else if (time === 9) {
                contextData.setTimeFilter('All program');
              } else {
                contextData.setTimeFilter(time);
              }
              setClockTextHeader(selectedTime);
              toggleFilter();
              history.push(`/map/${selectedCountry}/${selectedRegion}`);
            })
            .catch((err) => {
              if (err.response.status === 403) redirectToLandingOnError();
            });
        });
    }
    if (selectedCountry && !selectedRegion) {
      let url;
      if (time !== 1) {
        if (time === 0) {
          url = `/api/journeys/get_count/?country_id=${selectedCountry}&live=true`;
        } else {
          url = `/api/journeys/get_count/?country_id=${selectedCountry}&start_time=${time}`;
        }
      } else {
        url = `/api/journeys/get_count/?country_id=${selectedCountry}`;
      }
      axios
        .get(url, config)
        .then((response) => {
          if (response.data.length < 1) {
            contextData.setAlertText('No results, Please adjust the filters');
            contextData.setAlert(true);
            contextData.setTimeFilter(prevTimeFilter);
            toggleFilter();
            reset();
            return;
          }
          if (time === 0) {
            contextData.setTimeFilter('Live');
          } else if (time === 9) {
            contextData.setTimeFilter('All program');
          } else {
            contextData.setTimeFilter(time);
          }
          setClockTextHeader(selectedTime);
          toggleFilter();
          history.push(`/map/${selectedCountry}`);
        })
        .catch((error) => {
          if (error.response.status === 403) redirectToLandingOnError();
        });
    }
    if (!selectedCountry && !selectedRegion) {
      if (time === 0) {
        contextData.setTimeFilter('Live');
      } else if (time === 9) {
        contextData.setTimeFilter('All program');
      } else {
        contextData.setTimeFilter(time);
      }
      setClockTextHeader(selectedTime);
      toggleFilter();
    }
  };

  const myRef = React.useRef<HTMLDivElement>(null);

  const rightBorder = {
    borderRight: '1px solid rgb(153,153,153)',
  };

  /* eslint-disable */
  const leftBorder = {
    cursor: 'pointer',
    marginLeft:
      window.innerWidth <= 1024 && level === 2
        ? '30px'
        : window.innerWidth <= 1024 && level === 3
        ? '50px'
        : window.innerWidth <= 768 && level === 4
        ? '45px'
        : '',
  };
  /* eslint-enable */

  const rightBorderEnd = {
    borderRight: '1px solid rgb(153,153,153)',
    marginRight: '0px',
    paddingRight: window.innerWidth < 1000 ? '16px' : '0px',
  };

  const classes = useStyles();

  React.useEffect(() => {
    // using this effect hook to add data to the country and region select box
    axios
      .get('/api/continents/', config)
      .then((response) => {
        const countries = response?.data?.map((item: any) =>
          item?.countries?.map((country1: any) => ({
            name: country1.name,
            id: country1.id,
            flag: country1?.flag_image,
            kml: country1?.kml_file,
          })),
        );
        const allCountries = countries[0].sort((a: any, b: any) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        contextData.setKmlArray(allCountries);
        setCountry(allCountries);
      })
      .catch((error) => {
        if (error.response.status === 403) redirectToLandingOnError();
      });
  }, [level]);

  React.useEffect(() => {
    setSelectedTime(clockTextHeader);
    return () => {
      if (clockTextHeader !== DEFAULT_TIME) {
        contextData.setClockText(clockTextHeader);
      }
    };
  }, [clockTextHeader, level]);

  React.useEffect(() => {
    if (filterShow === true) {
      setSelectedRegion('');
    }
    if (selectedCountry) {
      axios
        .get(`/api/journeys/get_count/?country_id=${selectedCountry}`, config)
        .then((response: any) => {
          const regions = response?.data
            ?.map((item: any) => ({
              name: item.name,
              type: item.type,
              id: item.region_id,
            }))
            .sort((a: any, b: any) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });
          setRegion(regions);
        })
        .catch((error) => {
          if (error.response.status === 403) redirectToLandingOnError();
        });
    }
  }, [selectedCountry]);

  const gotoCountry = (countryID: any) => {
    history.push(`/map/${countryID}`);
  };

  const gotoRegion = (regionID: any) => {
    const path = history.location.pathname.slice(5);
    const pdata = path.split('/');
    if (pdata.length > 0) {
      history.push(`/map/${pdata[0]}/${regionID}`);
    }
  };

  const closeFilter = () => {
    setFilterShow(false);
    reset();
  };

  return (
    <>
      <Header data-cy="header" style={{ scrollBehavior: 'smooth' }} ref={myRef}>
        <section>
          <Flex>
            <BackIcon
              data-cy="go_back"
              onClick={goBack}
              src="/assets/icons/back_arrow.svg"
            />
            {!currentPath && (
              <Logo
                onClick={() => history.push('/')}
                data-cy="logo"
                style={rightBorder}
                src="/assets/logos/logo_red.svg"
              />
            )}
            {level === 2 && (
              <section style={{ display: 'flex', alignItems: 'center' }}>
                <Flag
                  data-cy={`${countryName?.name}_flag`}
                  src={countryName?.flag}
                />
                <CountryText
                  data-cy="country_name"
                  style={rightBorder}
                  onClick={() => gotoCountry(countryName.id)}
                >
                  {countryName?.name}
                </CountryText>
              </section>
            )}
            {level === 3 && (
              <section
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  minWidth: '100%',
                }}
              >
                <Flag
                  data-cy={`${countryName?.name}_flag`}
                  src={countryName?.flag}
                />
                <CountryText
                  data-cy="country_name"
                  style={rightBorder}
                  onClick={() => gotoCountry(countryName.id)}
                >
                  {countryName?.name}
                </CountryText>
                <RegionIcon src="/assets/icons/region.svg" />
                <CountryText
                  data-cy="region_name"
                  style={rightBorder}
                  onClick={() => gotoRegion(regionName.id)}
                >
                  {regionName?.name.length > 8 && window.innerWidth <= 1024
                    ? `${regionName?.name.slice(0, 8)}...`
                    : regionName?.name}
                </CountryText>
              </section>
            )}
            {level === 4 && (
              <section
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  minWidth: '100%',
                }}
              >
                <Flag
                  data-cy={`${countryName?.name}_flag`}
                  src={countryName?.flag}
                />
                <CountryText
                  data-cy="country_name"
                  style={rightBorder}
                  onClick={() => gotoCountry(countryName.id)}
                >
                  {countryName?.name}
                </CountryText>
                <RegionIcon src="/assets/icons/region.svg" />
                <CountryText
                  data-cy="region_name"
                  style={rightBorder}
                  onClick={() => gotoRegion(regionName.id)}
                >
                  {regionName?.name.length > 8 && window.innerWidth <= 1024
                    ? `${regionName?.name.slice(0, 8)}...`
                    : regionName?.name}
                </CountryText>
              </section>
            )}
          </Flex>
        </section>
        {!currentPath && (
          <Filter
            data-cy="map_filter_button"
            style={leftBorder}
            onClick={() => setFilterShow(!filterShow)}
          >
            <Icon src="/assets/icons/clock.svg" />
            <Text style={level === 4 ? rightBorderEnd : {}}>
              {clockTextHeader}
            </Text>
          </Filter>
        )}
        {level === 4 && (
          <Filter
            style={window.innerWidth > 1000 ? { paddingLeft: '27px' } : {}}
          >
            <Icon src="/assets/icons/hospital_grey.svg" />
            <Text data-cy="facility" title={facilityName}>
              {facilityName.length > 11 && window.innerWidth <= 1024
                ? `${facilityName.slice(0, 8)}...`
                : facilityName}{' '}
              {numOfJourneysByFacility !== 0 && `(${numOfJourneysByFacility})`}
            </Text>
          </Filter>
        )}
        {currentPath && currentPath?.length > 0 && (
          <>
            <Filter>
              <CurrentPathText
                style={currentPath === 'Statistics' ? rightBorder : {}}
              >
                {currentPath}
              </CurrentPathText>
              {currentPath === 'Statistics' && (
                <FormControl
                  className={classes.formControl}
                  variant="standard"
                  size="small"
                >
                  <InputLabel
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                    }}
                    htmlFor="outlined-period-native-simple"
                  >
                    <Icon
                      src="/assets/icons/clock.svg"
                      style={{ marginRight: '10px' }}
                    />
                    Year
                  </InputLabel>
                  <Select
                    // native
                    style={{ fontSize: '14px' }}
                    labelId="year-select-small"
                    label="Year"
                    inputProps={{
                      name: 'time',
                      id: 'outlined-period-native-simple',
                    }}
                    value={yearFilter}
                    onChange={(e: any) => {
                      setYearFilter(e.target.value);
                    }}
                  >
                    {yearOptions &&
                      yearOptions.length > 0 &&
                      yearOptions.map((item: any) => (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          style={{ fontSize: '14px' }}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </Filter>
          </>
        )}
        {level !== 1 && !currentPath && (
          <ScrollButton>
            <Icon
              data-cy="scroll_left"
              src="/assets/icons/greater_than.svg"
              onClick={() => {
                if (myRef.current) {
                  myRef.current.scrollLeft += 600;
                }
              }}
            />
          </ScrollButton>
        )}
        <Drawer anchor="top" open={filterShow}>
          <FilterHeader>
            <Icon onClick={closeFilter} src="/assets/icons/cross.svg" />
            <FilterText>Change filters</FilterText>
          </FilterHeader>
          <FilterSection data-cy="map_filter">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel
                style={{ display: 'flex', alignItems: 'center' }}
                htmlFor="outlined-country-native-simple"
              >
                <Icon
                  style={{ marginRight: '10px' }}
                  src="/assets/icons/flag.svg"
                />
                Country
              </InputLabel>
              <Select
                label="Country"
                inputProps={{
                  name: 'country',
                  id: 'outlined-country-native-simple',
                }}
                value={selectedCountry}
                onChange={(e: any) => {
                  setSelectedCountry(e.target.value);
                }}
              >
                <MenuItem selected value="">
                  All Countries
                </MenuItem>
                {country &&
                  country.length > 0 &&
                  country.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel
                style={{ display: 'flex', alignItems: 'center' }}
                htmlFor="outlined-region-native-simple"
              >
                <Icon
                  style={{ marginRight: '10px' }}
                  src="/assets/icons/region.svg"
                />
                Region
              </InputLabel>
              <Select
                label="Region"
                inputProps={{
                  name: 'region',
                  id: 'outlined-region-native-simple',
                }}
                value={selectedRegion}
                onChange={(e: any) => {
                  setSelectedRegion(e.target.value);
                }}
                disabled={selectedCountry === ''}
              >
                <MenuItem selected value="">
                  All Regions
                </MenuItem>
                {region &&
                  region.length > 0 &&
                  region.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel
                style={{ display: 'flex', alignItems: 'center' }}
                htmlFor="outlined-period-native-simple"
              >
                <Icon
                  src="/assets/icons/clock.svg"
                  style={{ marginRight: '10px' }}
                />
                Period of Time
              </InputLabel>
              <Select
                // native
                label="Period of Time"
                inputProps={{
                  name: 'time',
                  id: 'outlined-period-native-simple',
                }}
                value={selectedTime}
                onChange={(e: any) => {
                  setSelectedTime(e.target.value);
                }}
              >
                <MenuItem value="All program">All Program</MenuItem>
                {timeOptions &&
                  timeOptions.length > 0 &&
                  timeOptions.map((item: any) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button onClick={filterApply}>APPLY</Button>
          </FilterSection>
        </Drawer>
      </Header>
    </>
  );
};
