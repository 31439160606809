/* eslint-disable */
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { setTimeout } from 'timers';
import { Context } from '../../context';
import {
  HeaderText,
  Container,
  Logo,
  ButtonText as Text,
  DesktopHeader,
  MobileTabHeader,
  HeaderSection,
  Tile,
  Icon,
  ContentSection,
  DesktopLogOutButton,
  MobileLogOutButton,
} from './LandingPage.styles';
import { LogInForm } from '../../components/LogInForm';
import { RequestAccess } from '../../components/RequestAccess';
import { RequestSuccess } from '../../components/RequestSuccess';
import { RequestFail } from '../../components/RequestFail';
import { Loader } from '../../components/Loader';
import { Animation } from '../../components/Animation/Animation';

export const LandingPage: FC = () => {
  const history = useHistory();
  const [animation, setAnimation] = React.useState(true);
  const [isSignIn, setIsSignIn] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [inputError, setInputError] = React.useState(false);
  const [logInErrorMsg, setLogInErrorMsg] = React.useState('');
  const [accessErrorMsg, setAccessErrorMsg] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [accessType, setAccessType] = React.useState('temp');
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [guestAccessAllowed, setGuestAccessAllowed] = React.useState(false);

  const {
    tokenStorage,
    setTokens,
    clearStorage,
    setStatus,
    isLoggedIn,
    setIsLoggedIn,
    validateToken,
    logoUrlList,
  } = React.useContext(Context);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const resetStates = () => {
    setIsSignIn(true);
    setIsError(false);
    setIsSuccess(false);
    setInputError(false);
    setUserName('');
    setPassword('');
    setEmail('');
  };

  const resetTypes = () => {
    setAccessType('temp');
  };

  React.useEffect(() => {
    validateToken();
    axios.get('/api/continents/').then((res) => {
      if (res.data[0].ecd_guest_access) {
        setGuestAccessAllowed(true);
      }
    });
  }, []);

  const submitSignIn = (e: any) => {
    e.preventDefault();
    setIsLoaded(false);
    if (!userName || !password) {
      setInputError(true);
      setLogInErrorMsg('Please enter Username and Password');
      setIsLoaded(true);
      return;
    }
    axios
      .post('/api/login/', { username: userName, password })
      .then((res) => {
        if (res.data.access_token) {
          setTokens(res.data.access_token, tokenStorage);
          setStatus('true', tokenStorage);
          setIsLoggedIn(true);
          if (inputError) setInputError(false);
          if (logInErrorMsg) setLogInErrorMsg('');
          setIsLoaded(true);
        } else {
          setIsLoggedIn(false);
          setIsError(true);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setLogInErrorMsg(err.response.data.detail);
          setInputError(true);
        } else {
          setIsError(true);
        }
        setIsLoaded(true);
      });
  };

  const signOut = () => {
    clearStorage(tokenStorage);
    setIsLoggedIn(false);
    resetStates();
  };

  const submitAccessRequest = (e: any) => {
    e.preventDefault();
    setIsLoaded(false);
    if (!email) {
      setInputError(true);
      setAccessErrorMsg('Please enter an Email');
      setIsLoaded(true);
      return;
    }
    axios
      .post('/api/temporary_access/', { email })
      .then((res) => {
        console.log(res.data.message);
        setIsSuccess(true);
        setIsLoaded(true);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setAccessErrorMsg(err.response.data.detail);
          setInputError(true);
        } else {
          setIsError(true);
        }
        setIsLoaded(true);
      });
  };

  const gotoRouter = (route: string) => {
    history.push(route);
  };

  const { splashAnimation, setSplashAnimation } = React.useContext(Context);

  React.useEffect(() => {
    if (splashAnimation === false) {
      setTimeout(() => {
        setAnimation(false);
        setSplashAnimation(true);
      }, 1000 * (logoUrlList.length + 1.5));
    } else {
      setAnimation(false);
    }
  }, []);

  return (
    <>
      {animation === true ? (
        <Animation />
      ) : (
        <>
          {!isLoaded && <Loader />}
          <DesktopHeader>
            <Logo src="/assets/logos/logo_white.svg" />
          </DesktopHeader>
          <MobileTabHeader>
            <Logo data-cy="logo" src="/assets/logos/logo_white.svg" />
            <div />
          </MobileTabHeader>
          {isLoggedIn && (
            <DesktopLogOutButton data-cy="logout_button" onClick={signOut}>
              <Text
                style={{ fontSize: '14px', fontWeight: 500, color: '#FEF5F5' }}
              >
                LOG OUT
              </Text>
            </DesktopLogOutButton>
          )}
          <Container>
            <HeaderSection>
              <HeaderText>
                Emergency transport for pregnant women and newborns
              </HeaderText>
            </HeaderSection>
            {isLoggedIn ? (
              <>
                <ContentSection>
                  <Tile
                    style={{
                      marginRight: '42px',
                    }}
                    data-cy="go_to_map"
                    onClick={() => gotoRouter('/map')}
                  >
                    <div
                      style={{
                        backgroundColor: '#e60000',
                        borderRadius: '50%',
                        width: '88px',
                        height: '88px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon src="/assets/icons/maps_white.svg" />
                    </div>
                    <section>
                      <Text style={{ fontSize: '14px', fontWeight: 500 }}>
                        Map
                      </Text>
                    </section>
                  </Tile>
                  <Tile
                    data-cy="go_to_stats"
                    onClick={() => gotoRouter('/stats')}
                  >
                    <div
                      style={{
                        backgroundColor: '#e60000',
                        borderRadius: '50%',
                        width: '88px',
                        height: '88px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon src="/assets/icons/statistics_white.svg" />
                    </div>
                    <section>
                      <Text style={{ fontSize: '14px', fontWeight: 500 }}>
                        Statistics
                      </Text>
                    </section>
                  </Tile>
                </ContentSection>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '32px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#e60000',
                      color: '#fff',
                      padding: '10px 16px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      width: '198px',
                      cursor: 'pointer',
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '16px',
                      border: '1px solid #FFFFFF',
                      boxSizing: 'border-box',
                      borderRadius: '4px',
                    }}
                    onClick={() => gotoRouter('/about')}
                    data-cy="go_to_about"
                  >
                    About m-mama
                  </div>
                </div>
                <MobileLogOutButton data-cy="logout_button" onClick={signOut}>
                  <Text
                    style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#FEF5F5',
                    }}
                  >
                    LOG OUT
                  </Text>
                </MobileLogOutButton>
              </>
            ) : (
              <>
                <ContentSection>
                  {!isError &&
                    !isSuccess &&
                    (isSignIn ? (
                      <LogInForm
                        setIsSignIn={setIsSignIn}
                        userName={userName}
                        password={password}
                        onUserNameChange={onUserNameChange}
                        onPasswordChange={onPasswordChange}
                        submitSignIn={submitSignIn}
                        inputError={inputError}
                        setInputError={setInputError}
                        logInErrorMsg={logInErrorMsg}
                        guestAccessAllowed={guestAccessAllowed}
                      />
                    ) : (
                      <RequestAccess
                        accessType={accessType}
                        email={email}
                        onEmailChange={onEmailChange}
                        resetStates={resetStates}
                        resetTypes={resetTypes}
                        setAccessType={setAccessType}
                        submitAccessRequest={submitAccessRequest}
                        inputError={inputError}
                        accessErrorMsg={accessErrorMsg}
                      />
                    ))}
                  {!isError && isSuccess && (
                    <RequestSuccess
                      resetStates={resetStates}
                      resetTypes={resetTypes}
                    />
                  )}
                  {isError && !isSuccess && (
                    <RequestFail
                      resetStates={resetStates}
                      resetTypes={resetTypes}
                    />
                  )}
                </ContentSection>
                <section></section>
              </>
            )}
            <section></section>
          </Container>
        </>
      )}
    </>
  );
};
