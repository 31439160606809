import React, { FC } from 'react';
import { RequestSuccessProps } from './RequestSuccess.interface';
import {
  Container,
  IconContainer,
  Text,
  MessageContainer,
  Button,
} from './RequestSuccess.styles';

export const RequestSuccess: FC<RequestSuccessProps> = (props) => {
  const { resetStates, resetTypes } = props;

  return (
    <Container>
      <IconContainer>
        <img src="/assets/icons/success.svg" alt="success check icon" />
      </IconContainer>
      <Text style={{ fontSize: '20px', fontWeight: 500, color: '#474747' }}>
        Success
      </Text>
      <MessageContainer>
        <Text
          style={{
            fontSize: '14px',
            fontWeight: 400,
            color: 'rgba(33, 33, 33, 0.6)',
            textAlign: 'center' as 'center',
          }}
        >
          Access was successfully requested, please check your email.
        </Text>
      </MessageContainer>
      <Button data-cy="go_back">
        <Text
          style={{
            fontSize: '14px',
            fontWeight: 500,
            color: '#E60000',
            cursor: 'pointer',
          }}
          onClick={() => {
            resetStates();
            resetTypes();
          }}
        >
          GO BACK
        </Text>
      </Button>
    </Container>
  );
};
