/* eslint-disable */
import React, { FC } from 'react';
import './Animation.css';
import { Context } from '../../context';

export const Animation: FC = () => {
  const { logoUrlList } = React.useContext(Context);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#fff',
      }}
    >
      {logoUrlList.map((url: string, index: number) => {
        const countryName = url.split('_')[0];
        return (
          <div
            key={index}
            className={index === logoUrlList.length - 1 ? 'lastPic' : 'pic'}
            style={{ animationDelay: `${index}s`, marginBottom: '35vh' }}
          >
            <img className="animationImg" src={`/assets/animation/${url}`} />
            <div
              className="animationFont"
              style={{
                fontSize: index === logoUrlList.length - 1 ? '18px' : '22px',
                color: index === logoUrlList.length - 1 ? '#E60000' : 'black',
              }}
            >
              {index === logoUrlList.length - 1
                ? 'powered by Vodafone Foundation'
                : countryName}
            </div>
          </div>
        );
      })}
    </div>
  );
};
