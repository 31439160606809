import styled from 'styled-components';

const MapContainer = styled.div`
  height: calc(100vh - 56px);
  width: 100vw;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  @media (min-width: 768px) {
    height: calc(100vh - 88px);
  }
`;

export { MapContainer };
