import React, { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import { RequestAccessProps } from './RequestAccess.interface';
import {
  Text,
  Container,
  FormContainer,
  RedButton,
  // WhiteButton,
  // Content,
  Footer,
  FooterButton,
} from './RequestAccess.styles';

export const RequestAccess: FC<RequestAccessProps> = (props) => {
  const {
    accessType,
    // setAccessType,
    email,
    onEmailChange,
    resetStates,
    resetTypes,
    submitAccessRequest,
    inputError,
    accessErrorMsg,
  } = props;

  const styles = {
    title: {
      fontSize: '20px',
      fontWeight: 500,
      color: '#474747',
    },
    label: {
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(33, 33, 33, 0.6)',
    },
    RedButtonText: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#fff',
    },
    WhiteButtonText: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#E60000',
    },
    footerText: {
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(33, 33, 33, 0.6)',
      textAlign: 'center' as 'center',
      margin: '0px',
    },
    footerErrorText: {
      margin: '0px',
      fontSize: '14px',
      fontWeight: 500,
      color: '#E60000',
    },
    footerButtonText: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#E60000',
      cursor: 'pointer',
      textAlign: 'center' as 'center',
    },
  };

  // React.useEffect(() => {
  //   setResponseType('');
  // }, []);

  return (
    <>
      {/* {accessType === '' && (
        <Container>
          <Text style={styles.title}>Request Access</Text>
          <FormContainer>
            <Content>
              <Text style={styles.label}>
                Are you not on the approved list yet?
              </Text>
              <RedButton>
                <Text
                  style={styles.RedButtonText}
                  onClick={() => setAccessType('temp')}
                >
                  REQUEST TEMPORARY ACCESS
                </Text>
              </RedButton>
            </Content>
            <Content>
              <Text style={styles.label}>Are you on the list?</Text>
              <WhiteButton>
                <Text
                  style={styles.WhiteButtonText}
                  onClick={() => setAccessType('perm')}
                >
                  REQUEST PERMANENT ACCESS
                </Text>
              </WhiteButton>
            </Content>
          </FormContainer>
          <Footer>
            <Text style={styles.footerText}>You have an account?</Text>
            <FooterButton>
              <Text
                style={styles.footerButtonText}
                onClick={() => {
                  resetStates();
                  resetTypes();
                }}
              >
                SIGN IN
              </Text>
            </FooterButton>
          </Footer>
        </Container>
      )} */}
      {accessType === 'temp' && (
        <Container>
          <Text style={styles.title}>Request Temporary Access</Text>
          <FormContainer
            style={{ height: '108px' }}
            id="accessForm"
            onSubmit={(e) => submitAccessRequest(e)}
          >
            <TextField
              error={inputError}
              id="outlined-email-input"
              label="Email Address"
              variant="outlined"
              type="email"
              value={email}
              onChange={onEmailChange}
            />
            <RedButton type="submit" form="accessForm">
              <Text style={styles.RedButtonText}>SUBMIT</Text>
            </RedButton>
          </FormContainer>
          {inputError && (
            <Text style={styles.footerErrorText}>{accessErrorMsg}</Text>
          )}
          {/* <Footer>
            <Text style={styles.footerText}>
              Are you not on the approved list yet?
            </Text>
            <FooterButton>
              <Text
                style={styles.footerButtonText}
                onClick={() => setAccessType('perm')}
              >
                REQUEST PERMANENT ACCESS
              </Text>
            </FooterButton>
          </Footer> */}
          <Footer>
            <Text style={styles.footerText}>You have an account?</Text>
            <FooterButton data-cy="sign_in">
              <Text
                style={styles.footerButtonText}
                onClick={() => {
                  resetStates();
                  resetTypes();
                }}
              >
                SIGN IN
              </Text>
            </FooterButton>
          </Footer>
        </Container>
      )}
      {/* {accessType === 'perm' && (
        <Container>
          <Text style={styles.title}>Request Permanent Access</Text>
          <FormContainer style={{ height: '108px' }}>
            <TextField
              id="outlined-email-input"
              label="Email Address"
              variant="outlined"
              type="email"
              value={email}
              onChange={onEmailChange}
            />
            <RedButton>
              <Text style={styles.RedButtonText}>SUBMIT</Text>
            </RedButton>
          </FormContainer>
          <Footer>
            <Text style={styles.footerText}>Are you on the list?</Text>
            <FooterButton>
              <Text
                style={styles.footerButtonText}
                onClick={() => setAccessType('temp')}
              >
                REQUEST TEMPORARY ACCESS
              </Text>
            </FooterButton>
          </Footer>
          <Footer>
            <Text style={styles.footerText}>You have an account?</Text>
            <FooterButton>
              <Text
                style={styles.footerButtonText}
                onClick={() => {
                  resetStates();
                  resetTypes();
                }}
              >
                SIGN IN
              </Text>
            </FooterButton>
          </Footer>
        </Container>
      )} */}
    </>
  );
};
