import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Text, Button } from './NotFound.styles';

export const NotFound = () => {
  const history = useHistory();
  return (
    <Container>
      <Text>404 Not Found</Text>
      <Button onClick={() => history.push('/')}>Goto Home</Button>
    </Container>
  );
};
