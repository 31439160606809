import React, { FC } from 'react';
import { Container, Text } from './Alert.styles';

export const Alert: FC<any> = ({ textProp }) => (
  <>
    <Container data-cy="info_alert">
      <Text>{textProp}</Text>
    </Container>
  </>
);
