import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  ChartStatTop,
  ChartStatBottom,
  Container,
  DataDesc,
  Icon,
  NumData,
  Text,
  NumStat,
  StatsContainer,
  NumStatSection,
  Section,
  MainSection,
  ButtonB,
  ButtonT,
  ChartStatSection,
  ChartHeaderTextNormal,
  ChartHeaderTextContainer,
  TopSection,
  Logo,
} from './StatisticsPageLayout.styles';
import { TopNavbar as Header } from '../../components/TopNavbar';
import { Loader } from '../../components/Loader';
import { Context } from '../../context';

export const StatisticsPageLayout: FC = () => {
  const {
    tokenStorage,
    getAccessToken,
    redirectToLandingOnError,
    yearOptions,
    setYearOptions,
    yearFilter,
    getYear,
    resetYear,
    DEFAULT_YEAR,
  } = React.useContext(Context);
  const history = useHistory();
  const [value, setValue] = React.useState<any>(null);
  const [previousUrl, setPreviousUrl] = React.useState<any>(null);
  const [tabs, setTabs] = React.useState([] as any);
  const [emergencyOrigin, setEmergencyOrigin] = React.useState({} as any);
  const [avgCost, setAvgCost] = React.useState({} as any);
  const [pregnancyStage, setPregnancyStage] = React.useState({} as any);
  const [loading, setLoading] = React.useState(true);
  const [emergenciesYearWise, setEmergenciesYearWise] = React.useState(
    {} as any,
  );
  const [comingSoon, setComingSoon] = React.useState(false);
  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
    setComingSoon(false);
    resetYear();
  };
  const [totalEmergencies, setTotalEmergencies] = React.useState(0);
  const [livesSaved, setLivesSaved] = React.useState(0);
  const [emergenciesMaternal, setEmergenciesMaternal] = React.useState(0);
  const [emergenciesNeonatal, setEmergenciesNeonatal] = React.useState(0);
  const [emergenciesBoth, setEmergenciesBoth] = React.useState(0);
  const [drivers, setDrivers] = React.useState(0);
  const [ambulances, setAmbulances] = React.useState(0);
  const [idTypes, setIdTypes] = React.useState([] as any);
  const [tabType, setType] = React.useState('');
  const [comingSoonMsg, setComingSoonMsg] = React.useState('');
  const COLORS = [
    '#E60000',
    '#FFE5E5',
    '#841810',
    '#666666',
    'rgba(33, 33, 33, 0.3)',
  ];

  const accessToken: string = getAccessToken(tokenStorage);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  React.useEffect(() => {
    let isActive = true;
    axios
      .get('/api/continents/', config)
      .then((response: any) => {
        const data = [] as any;
        response.data.forEach((continent: any) => {
          data.push({
            label: continent.name, // ALL / AFRICA
            id: continent.id,
            type: 'continent',
          });

          const tempData = [];
          tempData.push({
            id: continent.id,
            type: 'continents',
          });
          continent.countries.forEach((country: any) => {
            tempData.push({
              id: country.id,
              type: 'regions',
            });
          });

          continent.countries.forEach((country: any) => {
            data.push({
              label: country.name,
              id: country.id,
              type: 'country',
            });
          });
          if (isActive === true) {
            resetYear(); // do not change order here
            setValue(continent.id);
            setIdTypes(tempData);
          }
        });
        if (isActive === true) {
          setTabs(data);
        }
      })
      .catch((error: any) => {
        if (error.response.status === 403) redirectToLandingOnError();
      });
    return () => {
      isActive = false;
    };
  }, []);

  React.useEffect(() => {
    // getting data from backend
    if (value == null) {
      return;
    }
    let type = 'continents';
    if (idTypes.length > 0) {
      // find the type of the id in idtypes value
      const temp = idTypes.find((id: any) => id.id === value);
      if (temp) {
        type = temp.type;
      }
    }
    const { startTime, endTime } = getYear(yearFilter);
    const url = `/api/${type}/${value}/get_stats/?start_time=${startTime}&end_time=${endTime}`;
    if (url === previousUrl) {
      return;
    }
    setPreviousUrl(url);
    setLoading(true);
    const continent = axios.get(url, config);
    continent
      .then((response: any) => {
        if (!response.data.total_count || response.data.total_count < 1) {
          axios
            .get(`/api/mmama_settings/?country_id=${value}`, config)
            .then((res: any) => {
              setComingSoonMsg(res.data[0].ecd_msg_stat);
              setLoading(false);
              setComingSoon(true);
            })
            .catch((error: any) => {
              if (error.response.status === 403) redirectToLandingOnError();
            });
          return;
        }
        setLoading(false);
        if (
          response.data.average_cost.length < 2 &&
          response.data.average_cost.length > 0
        ) {
          setType('Country'); // do not remove
        } else if (
          response.data.average_cost.length > 1 &&
          response.data.average_cost.length > 0
        ) {
          setType('All'); // do not remove
        }
        setTotalEmergencies(response?.data?.total_count);
        setDrivers(response?.data?.driver_count);
        setAmbulances(response?.data?.ambulance_count);
        const patient = response?.data?.patient_type;
        setEmergenciesMaternal(patient.MOTHER);
        setEmergenciesNeonatal(patient.BABY);
        setEmergenciesBoth(patient.BOTH);
        // const statusData = response?.data?.patient_status;
        const totalEmergencyOrigin = response.data.emergency_origin;
        const originEmergency = [] as any;
        totalEmergencyOrigin.forEach((origin: any) => {
          originEmergency.push({
            label: origin.name,
            healthcare: origin.origin.HEALTHCARE_FACILITY,
            village: origin.origin.VILLAGE,
          });
        });
        const originLabels = [] as any;
        const originHealthcare = [] as any;
        const originVillage = [] as any;
        originEmergency.forEach((origin: any) => {
          originLabels.push(origin.label);
          originHealthcare.push(origin.healthcare);
          originVillage.push(origin.village);
        });

        const tempOrigin = {
          labels: originLabels,
          datasets: [
            {
              label: 'Health Facility',
              backgroundColor: '#414141',
              borderColor: '#f5f5f5',
              borderWidth: 1,
              data: originHealthcare,
              maxBarThickness: 60,
            },
            {
              label: 'Village',
              backgroundColor: 'rgba(33, 33, 33, 0.3)',
              borderColor: '#f5f5f5',
              borderWidth: 1,
              data: originVillage,
              maxBarThickness: 60,
            },
          ],
        };
        setEmergencyOrigin(tempOrigin);
        setLivesSaved(response?.data?.lives_saved);

        const avgCostData = response?.data?.average_cost;
        const avgCostLabels = [] as any;
        const avgCostTrip = [] as any;

        avgCostData.forEach((cost: any) => {
          avgCostLabels.push(cost.name);
          avgCostTrip.push(Math.round(cost.average_usd));
        });

        if (avgCostData && avgCostData.length > 0) {
          const tempAvgCost = {
            labels: avgCostLabels,
            datasets: [
              {
                label: 'Price',
                backgroundColor: COLORS[0],
                borderColor: '#f5f5f5',
                borderWidth: 1,
                data: avgCostTrip,
                maxBarThickness: 60,
              },
            ],
          };
          setAvgCost(tempAvgCost);
        }
        const pregnancyData = response?.data?.pregnancy_stage;
        const pregnancyStageArr = [] as any;
        const pregnancyLabels = [] as any;
        Object.keys(pregnancyData[0]?.emergency_type).forEach((key: any) => {
          pregnancyLabels.push(key);
        });
        pregnancyData.forEach((stage: any) => {
          const objKeys = Object.keys(stage.emergency_type);
          const values = [] as any;
          objKeys.forEach((key: any) => {
            values.push(stage.emergency_type[key]);
          });
          pregnancyStageArr.push({ data: values, country: stage.name });
        });
        if (pregnancyStage && pregnancyStageArr.length > 0) {
          const tempPregnancy = {
            labels: pregnancyLabels,
            datasets: pregnancyStageArr.map((label: any, index: number) => ({
              label: label.country,
              backgroundColor: COLORS[index],
              borderColor: '#f5f5f5',
              borderWidth: 1,
              data: label.data,
              maxBarThickness: 60,
            })),
          };
          setPregnancyStage(tempPregnancy);
        }
        const tempEmergenciesYearWise = [] as any;
        const yearData = response?.data?.year_wise;
        const uniqueYearLabels = [] as any;
        const statsYearOptions = [] as any;
        yearData.forEach((year: any) => {
          Object.keys(year.year).forEach((key: any) => {
            if (!uniqueYearLabels.includes(key)) {
              uniqueYearLabels.push(key);
              statsYearOptions.push({ value: key, label: key });
            }
          });
        });
        if (yearOptions.length === 1 && yearFilter === DEFAULT_YEAR) {
          setYearOptions([...yearOptions, ...statsYearOptions]);
        }
        uniqueYearLabels.forEach((year: any) => {
          if (yearData?.length > 0) {
            /* eslint-disable */
            yearData.map((data: any) => {
              if (!Object.keys(data.year).includes(year)) {
                data.year[year] = 0;
              }
              return data;
            });
            /* eslint-enable */
          }
        });

        yearData.forEach((year: any) => {
          tempEmergenciesYearWise.push({
            country: year.name,
            data: Object.values(year.year),
            year: Object.keys(year.year),
          });
        });
        if (tempEmergenciesYearWise && tempEmergenciesYearWise.length > 0) {
          const tempYearWise = {
            labels: uniqueYearLabels,
            datasets: tempEmergenciesYearWise.map(
              (label: any, index: number) => ({
                label: label.country,
                backgroundColor: COLORS[index],
                borderColor: '#f5f5f5',
                borderWidth: 1,
                data: label.data,
                maxBarThickness: 60,
              }),
            ),
          };
          setEmergenciesYearWise(tempYearWise);
        }
      })
      .catch((error: any) => {
        if (error.response.status === 403) redirectToLandingOnError();
        setLoading(false);
        console.log('get_stats_error', error);
      });
  }, [value, yearFilter, yearOptions]);

  const gotoMap = () => {
    history.push('/map');
  };

  /* eslint-disable */
  const getOptions = (data: any) => ({
    plugins: {
      legend: {
        display: data?.datasets?.length > 1 ? true : false,
      },
    },
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
      },
      x: {
        beginAtZero: true,
        stacked: true,
      },
    },
  });

  // const getCountryName = (data: any) => {
  //   const x = tabs.find((tab: any) => tab.id === data);
  //   return x.label;
  // };

  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Header backUrl="/map" currentPath="Statistics" />

      <Container>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
        >
          {tabs &&
            tabs.length > 0 &&
            tabs.map((tab: any) => (
              <Tab key={tab.id} value={`${tab.id}`} label={tab.label} />
            ))}
        </Tabs>
        <StatsContainer>
          <TopSection>
            <Logo
              alt="logo"
              src="/assets/logos/logo_red.svg"
              onClick={() => history.push('/')}
            />
            <ButtonT onClick={gotoMap}>view map</ButtonT>
          </TopSection>
          {!comingSoon && (
            <MainSection>
              <Section>
                <NumStatSection>
                  <NumStat>
                    <Icon
                      style={{
                        width: '90px',
                      }}
                      src="/assets/icons/car_ambulance_red.svg"
                    />
                    <NumData>
                      {totalEmergencies?.toLocaleString('en-US')}
                    </NumData>
                    <DataDesc>Total emergencies</DataDesc>
                  </NumStat>
                  <NumStat>
                    <Icon src="/assets/icons/lives_saved_red.svg" />
                    <NumData>{livesSaved?.toLocaleString('en-US')}*</NumData>
                    <DataDesc>Estimated lives saved</DataDesc>
                  </NumStat>
                </NumStatSection>
                <ChartStatSection>
                  <ChartStatTop data-cy="num_of_emerg_total">
                    <ChartHeaderTextContainer>
                      {tabType === 'All' ? (
                        <ChartHeaderTextNormal>
                          Number of Emergencies per country per year
                        </ChartHeaderTextNormal>
                      ) : (
                        <ChartHeaderTextNormal>
                          Number of Emergencies per year
                        </ChartHeaderTextNormal>
                      )}
                    </ChartHeaderTextContainer>
                    {emergenciesYearWise &&
                      emergenciesYearWise.labels &&
                      emergenciesYearWise.labels.length > 0 && (
                        <Bar
                          style={{ padding: '20px' }}
                          height={window.innerWidth > 1100 ? 50 : 100}
                          width={window.innerWidth > 1100 ? 70 : 100}
                          options={getOptions(emergenciesYearWise)}
                          data={emergenciesYearWise}
                        />
                      )}
                  </ChartStatTop>
                </ChartStatSection>
              </Section>

              <Section>
                <NumStatSection>
                  <NumStat>
                    <Icon src="/assets/icons/pregnant_women_red.svg" />
                    <NumData>
                      {emergenciesMaternal?.toLocaleString('en-US')}
                    </NumData>
                    <DataDesc>Maternal emergencies</DataDesc>
                  </NumStat>
                  <NumStat>
                    <Icon src="/assets/icons/child_red.svg" />
                    <NumData>
                      {emergenciesNeonatal?.toLocaleString('en-US')}
                    </NumData>
                    <DataDesc>Newborn emergencies</DataDesc>
                  </NumStat>
                  <NumStat>
                    <span>
                      <Icon src="/assets/icons/pregnant_women_red.svg" />
                      <Icon src="/assets/icons/child_red.svg" />
                    </span>
                    <NumData>
                      {emergenciesBoth?.toLocaleString('en-US')}
                    </NumData>
                    <DataDesc>Mother & Baby emergencies</DataDesc>
                  </NumStat>
                </NumStatSection>
                <ChartStatSection>
                  <ChartStatTop data-cy="num_of_emerg_per_stage">
                    <ChartHeaderTextContainer>
                      <ChartHeaderTextNormal>
                        Number of Emergencies per stage of pregnancy
                      </ChartHeaderTextNormal>
                    </ChartHeaderTextContainer>
                    {pregnancyStage &&
                      pregnancyStage.labels &&
                      pregnancyStage.labels.length > 0 && (
                        <Bar
                          style={{ padding: '20px' }}
                          height={window.innerWidth > 1100 ? 50 : 100}
                          width={window.innerWidth > 1100 ? 70 : 100}
                          options={getOptions(pregnancyStage)}
                          data={pregnancyStage}
                        />
                      )}
                  </ChartStatTop>
                </ChartStatSection>
              </Section>
            </MainSection>
          )}

          {!comingSoon && (
            <Section>
              <ChartStatSection>
                <ChartStatBottom data-cy="num_of_emerg_per_origin">
                  <ChartHeaderTextContainer>
                    <ChartHeaderTextNormal>
                      Origin of the Emergencies village vs health facility
                    </ChartHeaderTextNormal>
                    {/* <ChartHeaderTextNormal>
                    village vs health facility
                  </ChartHeaderTextNormal> */}
                  </ChartHeaderTextContainer>
                  {emergencyOrigin &&
                    emergencyOrigin.labels &&
                    emergencyOrigin.labels.length > 0 && (
                      <Bar
                        style={{ padding: '20px' }}
                        height={window.innerWidth > 1100 ? 30 : 100}
                        width={window.innerWidth > 1100 ? 70 : 100}
                        options={{
                          indexAxis: 'y',
                          maintainAspectRatio: true,
                          responsive: true,
                          scales: {
                            y: {
                              beginAtZero: true,
                              stacked: true,
                            },
                            x: {
                              beginAtZero: true,
                              stacked: true,
                            },
                          },
                        }}
                        data={emergencyOrigin}
                      />
                    )}
                </ChartStatBottom>
              </ChartStatSection>
              <NumStatSection>
                <NumStat>
                  <Icon src="/assets/icons/local_taxi_red.svg" />
                  <NumData>{drivers?.toLocaleString('en-US')}</NumData>
                  <DataDesc>Community drivers trips</DataDesc>
                </NumStat>
                <NumStat>
                  <Icon src="/assets/icons/new_ambulance_red.svg" />
                  <NumData>{ambulances?.toLocaleString('en-US')}</NumData>
                  <DataDesc>Ambulances trips</DataDesc>
                </NumStat>
              </NumStatSection>

              <ChartStatSection>
                <ChartStatBottom data-cy="avg_cost">
                  <ChartHeaderTextContainer>
                    <ChartHeaderTextNormal>
                      Average cost of trip
                    </ChartHeaderTextNormal>
                  </ChartHeaderTextContainer>
                  {avgCost && avgCost.labels && avgCost.labels.length > 0 && (
                    <Bar
                      style={{ padding: '20px' }}
                      height={window.innerWidth > 1100 ? 30 : 100}
                      width={window.innerWidth > 1100 ? 70 : 100}
                      options={{
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                        indexAxis: 'y',
                        maintainAspectRatio: true,
                        responsive: true,
                        scales: {
                          y: {
                            beginAtZero: true,
                            stacked: true,
                          },
                          x: {
                            ticks: {
                              callback: (v: any) => `$${v}`,
                            },
                          },
                        },
                      }}
                      data={avgCost}
                    />
                  )}
                </ChartStatBottom>
              </ChartStatSection>
            </Section>
          )}

          {!comingSoon && (
            <Text>
              * Conservatively estimated at 25% reduction in maternal mortality
              demonstrated in previous studies
            </Text>
          )}

          {comingSoon && (
            <Text
              style={{
                fontSize: '16px',
              }}
            >
              {comingSoonMsg}
            </Text>
          )}
          {!comingSoon && (
            <ButtonB data-cy="view_map_button" onClick={gotoMap}>
              view map
            </ButtonB>
          )}
        </StatsContainer>
      </Container>
    </>
  );
};
