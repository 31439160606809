import React from 'react';

import * as Sentry from '@sentry/react';

import { Route, Switch, Redirect } from 'react-router-dom';
import { LandingPage } from '../containers/LandingPageLayout';
import './index.css';
import { Context } from '../context';
import { HomeLayout } from '../containers/HomePageLayout/HomeLayout';
import { StatisticsPageLayout } from '../containers/StatisticsPageLayout';
import { AboutPageLayout } from '../containers/AboutPageLayout/AboutPageLayout';
import { NotFound } from '../components/NotFound';
import { ReroutePageLayout } from '../containers/ReroutePageLayout';

const FallbackComponent = () => <div>An error has occurred</div>;

export const App: React.FC = () => {
  const { isLoggedIn } = React.useContext(Context);

  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route path="/map">
          {isLoggedIn ? <HomeLayout /> : <Redirect to="/" />}
        </Route>
        <Route exact path="/stats">
          {isLoggedIn ? <StatisticsPageLayout /> : <Redirect to="/" />}
        </Route>
        <Route exact path="/about">
          {isLoggedIn ? <AboutPageLayout /> : <Redirect to="/" />}
        </Route>
        <Route path="/autologin" component={ReroutePageLayout} />
        <Route component={NotFound} />
      </Switch>
    </Sentry.ErrorBoundary>
  );
};
