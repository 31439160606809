/* eslint-disable */
import React, { FC } from 'react';
import { LogInFormProps } from './LogInForm.interface';
import {
  Text,
  LogInContainer,
  LogInButton,
  FormContainer,
  FormFooter,
  FooterButton,
} from './LogInForm.styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export const LogInForm: FC<LogInFormProps> = (props) => {
  const {
    userName,
    password,
    setIsSignIn,
    onUserNameChange,
    onPasswordChange,
    submitSignIn,
    inputError,
    setInputError,
    logInErrorMsg,
    guestAccessAllowed,
  } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  const styles = {
    logInContainer: {
      height: '340px',
    },
    title: {
      fontSize: '20px',
      fontWeight: 500,
      color: '#474747',
    },
    formContainer: {
      height: '180px',
    },
    submitButtonText: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#fff',
    },
    footerText: {
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(33, 33, 33, 0.6)',
    },
    footerButtonText: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#E60000',
      cursor: 'pointer',
    },
    footerErrorText: {
      margin: '0px',
      fontSize: '14px',
      fontWeight: 500,
      color: '#E60000',
    },
    input: {
      width: '418px',
      // height: "48px",
      borderColor: 'rgba(33, 33, 33, 0.6)',
      // boxSizing: "border-box",
      // borderRadius: "4px",
    },
  };

  // const labels = React.useCallback((signIn: boolean) => ({
  //   title: signIn ? 'Sign in' : 'Request Access',
  //   buttonText: signIn ? 'SIGN IN' : 'SUBMIT',
  //   footerText: signIn ? "You don't have an account?" : "You have an account?",
  //   footerButtonText: signIn ? "REQUEST ACCESS" : "SIGN IN",
  // }), [signIn]);

  return (
    <LogInContainer style={styles.logInContainer}>
      <FormContainer
        style={styles.formContainer}
        id="logInForm"
        onSubmit={(e) => submitSignIn(e)}
      >
        <TextField
          error={inputError}
          autoComplete="off"
          id="outlined-email-input"
          label="Username"
          variant="outlined"
          type="text"
          value={userName}
          onChange={onUserNameChange}
        />
        <TextField
          error={inputError}
          id="outlined-adorment-password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          value={password}
          onChange={onPasswordChange}
          onKeyPress={(e) => (e.key === 'Enter' ? submitSignIn(e) : null)}
          InputProps={{
            // classes: {
            //   notchedOutline: styles.input,
            // },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LogInButton type="submit" form="logInForm">
          <Text style={styles.submitButtonText}>SIGN IN</Text>
        </LogInButton>
      </FormContainer>
      {inputError && (
        <Text style={styles.footerErrorText}>{logInErrorMsg}</Text>
      )}
      {guestAccessAllowed && (
        <FormFooter>
          <Text style={styles.footerText}>You don't have an account?</Text>
          <FooterButton data-cy="request_access">
            <Text
              style={styles.footerButtonText}
              onClick={() => {
                setIsSignIn(false);
                setInputError(false);
              }}
            >
              REQUEST ACCESS
            </Text>
          </FooterButton>
        </FormFooter>
      )}
    </LogInContainer>
  );
};
