import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 638px;
  height: 340px;
  box-shadow: 0px 6.875px 20.625px -8.25001px rgba(24, 39, 75, 0.12),
    0px 24.75px 121px -5.5px rgba(24, 39, 75, 0.14),
    0px 11px 38.5px -8.25001px rgba(24, 39, 75, 0.12),
    0px 24.75px 121px -5.5px rgba(24, 39, 75, 0.14);
  border-radius: 11px;
  @media (max-width: 767px) {
    width: 344px;
  }
  @media (max-width: 374px) {
    width: 312px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  background: #414141;
  border-radius: 78.3751px;
  margin: 10px 0px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    width: 80%;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 8px;
  @media (max-width: 767px) {
    padding: 0px;
  }
`;

export { Container, IconContainer, Text, MessageContainer, Button };
