import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  display: flex;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;

export { Container };
