import styled from 'styled-components';

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

const LogInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #ffffff;
  width: 638px;
  height: 340px;
  box-shadow: 0px 6.875px 20.625px -8.25001px rgba(24, 39, 75, 0.12),
    0px 24.75px 121px -5.5px rgba(24, 39, 75, 0.14),
    0px 11px 38.5px -8.25001px rgba(24, 39, 75, 0.12),
    0px 24.75px 121px -5.5px rgba(24, 39, 75, 0.14);
  border-radius: 11px;
  @media (max-width: 767px) {
    width: 344px;
  }
  @media (max-width: 374px) {
    width: 312px;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 180px;
  width: 418px;
  @media (max-width: 767px) {
    width: 312px;
    height: 172px;
  }
  @media (max-width: 374px) {
    width: 300px;
  }
`;

const LogInButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  width: 418px;
  height: 36px;
  background: #e60000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 312px;
    height: 28px;
  }
  @media (max-width: 374px) {
    width: 300px;
  }
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 180px;
    height: 59px;
  }
`;

const FooterButton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 8px;
  @media (max-width: 767px) {
    padding: 0px;
  }
`;

export {
  LogInContainer,
  LogInButton,
  Text,
  FormContainer,
  FormFooter,
  FooterButton,
};
