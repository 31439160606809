import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StartTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border: 1px solid #474747;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 8px 28px;
  min-height: 56px;
`;

const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
`;

const Icon = styled.img``;

export { Text, Container, StartTime, Icon };
