import React, { FC } from 'react';
import { RequestFailProps } from './RequestFail.interface';
import {
  Container,
  IconContainer,
  Text,
  MessageContainer,
  Button,
} from './RequestFail.styles';

export const RequestFail: FC<RequestFailProps> = (props) => {
  const { resetStates, resetTypes } = props;

  return (
    <Container>
      <IconContainer>
        <img src="/assets/icons/fail.svg" alt="fail cross icon" />
      </IconContainer>
      <Text style={{ fontSize: '20px', fontWeight: 500, color: '#474747' }}>
        Whoops, something went wrong...
      </Text>
      <MessageContainer>
        <Text
          style={{
            fontSize: '14px',
            fontWeight: 400,
            color: 'rgba(33, 33, 33, 0.6)',
            textAlign: 'center' as 'center',
          }}
        >
          Please try again or check with your m-mama point of contact.
        </Text>
      </MessageContainer>
      <Button data-cy="go_back">
        <Text
          style={{
            fontSize: '14px',
            fontWeight: 500,
            color: '#E60000',
            cursor: 'pointer',
          }}
          onClick={() => {
            resetStates();
            resetTypes();
          }}
        >
          GO BACK
        </Text>
      </Button>
    </Container>
  );
};
