import React, { FC } from 'react';
import { Drawer } from '@material-ui/core';
import { Route, useHistory, Switch } from 'react-router-dom';
import { HomeLayoutProps } from './HomeLayout.interface';
import {
  FabButton,
  StatIcon,
  FloatingWindow,
  LiveModal,
  AlertModal,
  InstructionModal,
} from './HomeLayout.styles';
import { TopNavbar as Header } from '../../components/TopNavbar';
import { BasicStatistics } from '../../components/BasicStatistics';
import { LiveAlert } from '../../components/LiveAlert';
import { LiveDetails } from '../../components/LiveDetails';
import { MapCountry } from '../../components/MapCountry';
import { MapRegion } from '../../components/MapRegion';
import { MapFacility } from '../../components/MapFacility';
import { MapRoutes } from '../../components/MapRoutes';
import { Context } from '../../context';
import { NotFound } from '../../components/NotFound';
import { Alert } from '../../components/Alert';

export const HomeLayout: FC<HomeLayoutProps> = () => {
  // const { isLoggedIn } = props;
  const {
    regionRoutes,
    level,
    vehicleInstruction,
    DEFAULT_TIME,
    isLive,
    timeFilter,
    alertText,
    alert,
  } = React.useContext(Context);
  const [openHistoric, setOpen] = React.useState(false);

  const toggleDrawerHistoric = () => {
    setOpen(!openHistoric);
  };

  const [openLive, setOpenLive] = React.useState(false);

  const toggleDrawerLive = () => {
    setOpenLive(!openLive);
  };

  const history = useHistory();
  const notfoundLength = history.location.pathname.split('/').length;

  /* eslint-disable */

  return (
    <>
      {notfoundLength < 6 && (
        <Header backUrl={history.location.pathname} level={level.level} />
      )}
      {regionRoutes.region === false && timeFilter !== 'Live' && (
        <FabButton data-cy="statistics" onClick={toggleDrawerHistoric}>
          <StatIcon src="/assets/icons/statistic.svg" /> <span>STATISTICS</span>
        </FabButton>
      )}
      {timeFilter === 'Live' && level.level === 4 && (
        <FabButton data-cy="details" onClick={toggleDrawerLive}>
          <StatIcon src="/assets/icons/details.svg" /> <span>DETAILS</span>
        </FabButton>
      )}
      {isLive.live === true && timeFilter !== 'Live' && (
        <LiveModal>
          <LiveAlert
            textProp="Live emergencies are ongoing"
            btnText="GO LIVE"
            action="Live"
          />
        </LiveModal>
      )}
      {isLive.live === false && timeFilter !== 'Live' && (
        <LiveModal>
          <LiveAlert textProp="No live emergencies are ongoing right now" />
        </LiveModal>
      )}
      {alert && (
        <AlertModal>
          <Alert textProp={alertText} />
        </AlertModal>
      )}
      {timeFilter === 'Live' && (
        <LiveModal>
          <LiveAlert
            action={DEFAULT_TIME}
            textProp="See historical data"
            btnText="VIEW HISTORICAL"
          />
        </LiveModal>
      )}
      {vehicleInstruction === true &&
        timeFilter === 'Live' &&
        level.level === 4 && (
          <InstructionModal data-cy="select_vehicle_info">
            <LiveAlert
              action={DEFAULT_TIME}
              textProp="Select the vehicle and see the details"
              button={true}
            />
          </InstructionModal>
        )}
      <Drawer
        onClose={toggleDrawerHistoric}
        anchor={window.innerWidth > 750 ? 'right' : 'bottom'}
        open={openHistoric}
      >
        <BasicStatistics toggle={toggleDrawerHistoric} />
      </Drawer>
      <Drawer
        onClose={toggleDrawerLive}
        open={openLive}
        anchor={window.innerWidth > 750 ? 'right' : 'bottom'}
      >
        <LiveDetails toggle={toggleDrawerLive} />
      </Drawer>
      {timeFilter === 'Live' && level.level === 4 && notfoundLength < 6 && (
        <FloatingWindow>
          <LiveDetails />
        </FloatingWindow>
      )}
      {regionRoutes.region === false &&
        timeFilter !== 'Live' &&
        notfoundLength < 6 && (
          <FloatingWindow>
            <BasicStatistics />
          </FloatingWindow>
        )}
      <Switch>
        <Route component={MapCountry} exact path="/:continentId" />
        <Route component={MapRegion} exact path="/:continentId/:countryId" />
        <Route
          component={MapFacility}
          exact
          path="/:continentId/:countryId/:regionId"
        />
        <Route
          component={MapRoutes}
          exact
          path="/:continentId/:countryId/:regionId/:healthFacilityId"
        />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};
