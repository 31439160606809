import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import axios from 'axios';
import { MapContainer } from './MapRegion.styles';
import { MapRegionProps } from './MapRegion.interface';
import { Loader } from '../Loader';
import { Context } from '../../context';

export const MapRegion: FC<MapRegionProps> = (props) => {
  const { match } = props;
  const history = useHistory();
  const [markers, setMarkers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [googleMap, setGoogleMap] = React.useState(null as any);
  const [totalJourneysStat, setTotalJourneys] = React.useState(0);
  const [statsMini, setStats] = React.useState({} as any);
  const {
    setMiniStats,
    setRegionRoutes,
    setLevel,
    setLocation,
    timeFilter,
    setAlert,
    setAlertText,
    gotoLiveCasesLocation,
    getTime,
    tokenStorage,
    getAccessToken,
    redirectToLandingOnError,
    // defaultCenter,
  } = React.useContext(Context);

  const accessToken: string = getAccessToken(tokenStorage);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const isMounted = React.useRef(true);

  React.useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  // const [center, setCenter] = React.useState(defaultCenter);
  const addMiniStats = (id: any) => {
    if (timeFilter === 'Live') return;
    let miniStatsUrl = '';
    if (
      timeFilter === 'All program' &&
      typeof timeFilter === 'string' &&
      !timeFilter.includes('Last')
    ) {
      miniStatsUrl = `/api/regions/${id}/get_basic_stats/`;
    } else {
      miniStatsUrl = `/api/regions/${id}/get_basic_stats/?start_time=${timeFilter}`;
      if (typeof timeFilter === 'string' && timeFilter.includes('Last')) {
        miniStatsUrl = `/api/regions/${id}/get_basic_stats/?start_time=${getTime(
          timeFilter,
        )}`;
      }
    }
    const miniStats = axios.get(miniStatsUrl, config);
    miniStats
      .then((stats: any) => {
        if (isMounted.current) {
          const statsData = stats.data;
          const totalEmergencies = statsData.total_count;
          const livesSaved = statsData.lives_saved;
          const maternal = statsData.patient_type.MOTHER;
          const neonatal = statsData.patient_type.BABY;
          const both = statsData.patient_type.BOTH;

          const finalData = {
            totalEmergencies,
            livesSaved,
            maternal,
            neonatal,
            both,
          };
          setRegionRoutes({ region: false });
          setStats(finalData);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) redirectToLandingOnError();
      });
  };

  React.useEffect(() => {
    if (totalJourneysStat !== 0) {
      const newObj = { ...statsMini, totalJourneys: totalJourneysStat };
      setMiniStats(newObj);
    }
  }, [statsMini, totalJourneysStat]);

  React.useEffect(() => {
    setLocation(history.location.pathname);
  }, [history.location.pathname]);

  React.useEffect(() => {
    setLevel({ level: 2 });
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    /* eslint-disable no-param-reassign */
    try {
      const url = match.url.slice(5);
      axios
        .get(`/api/regions/${url}/`, config)
        .then((response) => {
          if (isMounted.current) {
            addMiniStats(url);
            if (response.data.children.length > 1) {
              // if there are more than 1 region
              const markerData: any = response.data.children;
              let dataUrl = '';
              if (timeFilter === 'Live') {
                dataUrl = `/api/journeys/get_count/?country_id=${url}&live=true`;
              } else if (
                timeFilter === 'All program' &&
                typeof timeFilter === 'string' &&
                !timeFilter.includes('Last')
              ) {
                dataUrl = `/api/journeys/get_count/?country_id=${url}`;
              } else {
                dataUrl = `/api/journeys/get_count/?country_id=${url}&start_time=${timeFilter}`;
                if (
                  typeof timeFilter === 'string' &&
                  timeFilter.includes('Last')
                ) {
                  dataUrl = `/api/journeys/get_count/?country_id=${url}&start_time=${getTime(
                    timeFilter,
                  )}`;
                }
              }
              axios
                .get(dataUrl, config)
                .then((res: any) => {
                  setIsLoading(false);
                  const totalJourneys = res.data.reduce(
                    (accumulator: any, currentValue: any) =>
                      accumulator + currentValue.total_count,
                    0,
                  );
                  setTotalJourneys(totalJourneys);
                  const idWIthCount: { id: any; count: any }[] = [];
                  res.data.forEach((region: any) => {
                    idWIthCount.push({
                      id: region.region_id,
                      count: region.total_count,
                    });
                  });
                  markerData.forEach((marker: any) => {
                    const markerWithCount = idWIthCount.find(
                      (region: any) => region.id === marker.id,
                    );
                    if (markerWithCount) {
                      marker.total_count = markerWithCount.count;
                    }
                  });
                  let noneZeroMarkerData = markerData.filter(
                    (marker: any) => marker.total_count > 0,
                  );
                  noneZeroMarkerData = noneZeroMarkerData.filter(
                    (marker: any) =>
                      marker.location_lat !== null ||
                      marker.location_lon !== null,
                  );
                  if (noneZeroMarkerData.length > 0) {
                    // const position = {
                    //   lat: noneZeroMarkerData[0].location_lat,
                    //   lng: noneZeroMarkerData[0].location_lon,
                    // };
                    // setCenter(position);
                    setMarkers(noneZeroMarkerData);
                  } else {
                    // eslint-disable-next-line no-lonely-if
                    if (timeFilter === 'Live') {
                      // setAlertText('No Live Case Found');
                      // setAlert(true);
                      gotoLiveCasesLocation(history.location.pathname.slice(5));
                    } else {
                      setAlertText('No Cases Found');
                      setAlert(true);
                    }
                  }
                })
                .catch((error: any) => {
                  setIsLoading(false);
                  console.log('error -', error);
                });
            } else {
              // if there is only 1 region and we need to display district level in the map
              let dataUrl = '';
              if (timeFilter === 'Live') {
                dataUrl = `/api/journeys/get_count/?country_id=${url}&live=true`;
              } else if (
                timeFilter === '' ||
                timeFilter === 'All program' ||
                timeFilter === null
              ) {
                dataUrl = `/api/journeys/get_count/?country_id=${url}`;
              } else {
                dataUrl = `/api/journeys/get_count/?country_id=${url}&start_time=${timeFilter}`;
              }
              axios
                .get(dataUrl, config)
                .then((res: any) => {
                  if (isMounted.current) {
                    setIsLoading(false);
                    const totalJourneys = res.data.reduce(
                      (accumulator: any, currentValue: any) =>
                        accumulator + currentValue.total_count,
                      0,
                    );
                    setTotalJourneys(totalJourneys);
                    let noneZeroMarkerData = res.data.filter(
                      (marker: any) => marker.total_count > 0,
                    );
                    noneZeroMarkerData = noneZeroMarkerData.filter(
                      (marker: any) =>
                        marker.location_lat !== null ||
                        marker.location_lon !== null,
                    );
                    if (noneZeroMarkerData.length > 0) {
                      // const position = {
                      //   lat: noneZeroMarkerData[0].location_lat,
                      //   lng: noneZeroMarkerData[0].location_lon,
                      // };
                      // setCenter(position);
                      setMarkers(noneZeroMarkerData);
                    } else {
                      // eslint-disable-next-line no-lonely-if
                      if (timeFilter === 'Live') {
                        // setAlertText('No Live Case Found');
                        // setAlert(true);
                        gotoLiveCasesLocation(
                          history.location.pathname.slice(5),
                        );
                      } else {
                        setAlertText('No Cases Found');
                        setAlert(true);
                      }
                    }
                  }
                })
                .catch((error: any) => {
                  setIsLoading(false);
                  if (error.response.status === 403) redirectToLandingOnError();
                });
            }
          }
        })
        .catch((error: any) => {
          console.log('error', error);
          setIsLoading(false);
          if (error.response.status === 403) redirectToLandingOnError();
          history.push('/pagenotfound');
        });
    } catch (e: any) {
      console.error('Error:- ', e);
      setIsLoading(false);
      if (e.response.status === 403) redirectToLandingOnError();
    }
  }, [timeFilter, match.url]);

  const mapStyles = {
    width: '100%',
    height: '100%',
  };

  const mapOptions = {
    disableDefaultUI: true,
    // scrollwheel: false,
  };

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

  const getLabel = (number: number) => {
    if (number === undefined) return '';
    return {
      text: number.toLocaleString('en-US'),
      color: 'white',
    };
  };

  const getLocation = (lat: number, lon: number) => {
    if (lat === undefined || lon === undefined) return { lat: 0, lng: 0 };
    return {
      lat,
      lng: lon,
    };
  };

  const gotoNextLevel = (type: string, id: any) => {
    history.push(`/map/${match.url.slice(5)}/${id}`);
  };

  const iconHistoric = {
    url: '/assets/icons/marker_blue.svg',
    anchor: googleMap !== null ? new google.maps.Point(37, 37) : null,
  };

  const iconLive = {
    url: '/assets/icons/marker_red.svg',
    anchor: googleMap !== null ? new google.maps.Point(37, 37) : null,
  };

  /* added below to fitbounds */
  React.useEffect(() => {
    if (markers.length > 0 && googleMap !== null) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker: any) => {
        bounds.extend(
          new window.google.maps.LatLng(
            marker.location_lat,
            marker.location_lon,
          ),
        );
      });
      googleMap.fitBounds(bounds);
      if (markers.length === 1) {
        googleMap.setZoom(8);
      } else {
        let zoomLevel = googleMap.zoom;
        if (window.innerWidth > 768) zoomLevel -= 1;
        googleMap.setZoom(zoomLevel);
      }
    }
  }, [markers]);

  const onLoading = (map: any) => {
    setIsLoading(false);
    setGoogleMap(map);
  };

  return (
    <>
      <MapContainer data-cy="country_map">
        {isLoading ? <Loader /> : <></>}
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            // center={center}
            mapContainerStyle={mapStyles}
            options={mapOptions}
            onLoad={onLoading}
          >
            {markers &&
              markers.length > 0 &&
              markers.map((marker: any, index: any) => (
                <div
                  id={`marker_country_${index}`}
                  key={marker.id ? marker.id : marker.region_id}
                >
                  <Marker
                    icon={timeFilter === 'Live' ? iconLive : iconHistoric}
                    label={getLabel(marker.total_count)}
                    position={getLocation(
                      Number(marker.location_lat),
                      Number(marker.location_lon),
                    )}
                    onClick={() => {
                      gotoNextLevel(
                        marker.type,
                        marker.id ? marker.id : marker.region_id,
                      );
                    }}
                  />
                </div>
              ))}
          </GoogleMap>
        </LoadScript>
      </MapContainer>
    </>
  );
};
