import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { BasicStatisticsProps } from './BasicStatistics.interface';
import {
  Container,
  HeaderBox,
  Icon,
  LocationText,
  Row,
  SubText,
  Grid,
  GridItem,
  IconM,
  IconChild,
  StatValue,
  StatText,
  AllStatsLink,
  Text,
  Spacing,
} from './BasicStatistics.styles';
import { Context } from '../../context';

export const BasicStatistics: FC<BasicStatisticsProps> = (props) => {
  const history = useHistory();
  const contextData = React.useContext(Context);
  const gotoStats = () => {
    history.push('/stats');
  };
  const { toggle } = props;
  const { level } = contextData;
  const {
    livesSaved,
    maternal,
    neonatal,
    totalEmergencies,
    totalJourneys,
    both,
  } = contextData.miniStats;

  return (
    <>
      <Container data-cy="mini_stats">
        <HeaderBox>
          <Row>
            <SubText>M-MAMA OVERVIEW</SubText>
            <Icon
              onClick={() => toggle()}
              data-cy="close"
              src="/assets/icons/cross.svg"
            />
          </Row>
          <LocationText data-cy="location">
            {contextData?.countryMini?.name}
          </LocationText>
        </HeaderBox>
        <Grid>
          <GridItem>
            <IconM
              style={{
                width: '80px',
              }}
              src="/assets/icons/car_ambulance_white.svg"
            />
            <StatValue>
              {totalEmergencies?.toLocaleString('en-US') ?? 0}
            </StatValue>
            <StatText>Total emergencies</StatText>
          </GridItem>
          <GridItem>
            <IconM src="/assets/icons/lives_saved_white.svg" />
            <StatValue>{livesSaved?.toLocaleString('en-US') ?? 0}**</StatValue>
            <StatText>Estimated lives saved</StatText>
          </GridItem>
          <GridItem>
            <IconM src="/assets/icons/pregnant_women.svg" />
            <StatValue>{maternal?.toLocaleString('en-US') ?? 0}</StatValue>
            <StatText>Maternal emergencies</StatText>
          </GridItem>
          <GridItem>
            <IconM src="/assets/icons/child_white.svg" />
            <StatValue>{neonatal?.toLocaleString('en-US') ?? 0}</StatValue>
            <StatText>Newborn emergencies</StatText>
          </GridItem>
          <GridItem>
            <span>
              <IconM src="/assets/icons/pregnant_women.svg" />
              <IconChild src="/assets/icons/child_white.svg" />
            </span>
            <StatValue>{both?.toLocaleString('en-US') ?? 0}</StatValue>
            <StatText>Mother & Baby emergencies</StatText>
          </GridItem>
          <GridItem>
            <IconM
              style={{
                height: '53px',
                width: '53px',
                marginTop: '-10px',
                marginBottom: '-10px',
              }}
              src="/assets/icons/noj.svg"
            />
            <StatValue>{totalJourneys?.toLocaleString('en-US') ?? 0}</StatValue>
            <StatText>Number of trips</StatText>
          </GridItem>
        </Grid>
        {/* <Text style={{ marginTop: '24px' }}>
          * Numbers on the map may be higher due to multiple journeys per case.
        </Text> */}
        <Text style={{ marginTop: '24px' }}>
          * Each emergency can have multiple trips.
        </Text>
        <Text style={{ marginTop: '5px' }}>
          ** Conservatively estimated at 25% reduction in maternal mortality
          demonstrated in previous studies
        </Text>
        {level.level !== 3 ? (
          <AllStatsLink onClick={gotoStats}>see all statistics</AllStatsLink>
        ) : (
          <Spacing />
        )}
      </Container>
    </>
  );
};
