import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const Text = styled.p`
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.25px;
  color: #000;
`;

const Button = styled.button`
  background: #e60000;
  border: none;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
  margin: 10px 25px;
  @media (min-width: 768px) {
    height: 36px;
  }
`;

export { Container, Text, Button };
