import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { TopNavbar as Header } from '../../components/TopNavbar';
import {
  Container,
  Paragraph,
  Heading,
  Image,
  InnerLogo,
  Logo,
  LogoContainer,
} from './AboutPageLayout.styles';

export const AboutPageLayout: FC = () => {
  const history = useHistory();

  return (
    <>
      <Header backUrl="/" currentPath="About" />
      <Container>
        <LogoContainer>
          <InnerLogo>
            <Logo
              src="/assets/logos/logo_red.svg"
              onClick={() => history.push('/')}
            />
          </InnerLogo>
        </LogoContainer>
        <Heading>
          m-mama: Emergency transport for pregnant women and newborn
        </Heading>
        <Paragraph>
          The m-mama platform uses mobile technology to connect pregnant women
          and newborns to care when they need it the most. The affordable system
          is helping to reduce high rates of maternal mortality, identified as
          the number one health challenge by the United Nations’ Sustainable
          Development Goals.
        </Paragraph>
        <Paragraph>
          Thousands of pregnant women and their newborns across rural Africa are
          unable to travel to clinics or hospital when they experience a
          pregnancy-related emergency, resulting in high rates of death and
          disability. It is a global tragedy but also a devastating reality for
          too many families across the continent.
        </Paragraph>
        <div style={{ padding: '16px' }}>
          <Image src="/assets/images/aboutpage_img1.png" alt="img1" />
        </div>
        <Paragraph>
          m-mama is a cost-effective emergency transport system that manages a
          network of local private cars to act as ‘taxi ambulances’ in areas
          where ambulances are rarely available.
        </Paragraph>
        <Paragraph>
          When the patient or caregiver makes a free call to a 24/7 dispatch
          centre, a call handler assesses the patient’s condition using the
          m-mama app, which indicates the patient’s need for transfer to a
          health facility.
        </Paragraph>
        <Paragraph>
          In an emergency the dispatch checks for the availability of an
          ambulance. In many cases no ambulance is available, so the nearest
          taxi ambulance driver is identified on the m-mama app and receives a
          request to take the woman to the closest health facility. On safe
          arrival, the driver is paid instantly for the journey via M-Pesa (the
          mobile money transfer system) or via other government approved payment
          methods.
        </Paragraph>
        <div style={{ padding: '16px' }}>
          <Image src="/assets/images/aboutpage_img2.png" />
        </div>
        <Paragraph>
          The programme has seen a 27% reduction in maternal mortality in the
          Lake Zone region of Tanzania where it was launched.
        </Paragraph>
        <Paragraph>
          The national scale-up of m-mama in multiple countries is being funded
          through a financial and implementation partnership between Vodafone
          Foundation and National Governments. Further funding support is
          provided by Grand Challenges Canada. Technical support and
          implementation is provided by the Touch Foundation, Pathfinder
          International, Clinton Health Access Initiative (CHAI) and local
          government officials and facilities across the countries of operation.
          The m- mama system is sustained through local and national government
          funding and it is they who provide the operating staff and own the
          system.
        </Paragraph>
      </Container>
    </>
  );
};
