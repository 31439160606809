import styled from 'styled-components';

const Container = styled.div`
  background: rgba(234, 234, 234, 0.5);
  border-radius: 12px 12px 0px 0px;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0px 40px 0px;
  @media (min-width: 1100px) {
    padding: 72px 0px 40px 0px;
  }
  @media (min-width: 768px) {
    padding: 48px 0px 40px 0px;
  }
`;

const Paragraph = styled.p`
  padding: 24px 16px 0px 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.44px;
  color: #474747;
  text-align: left;
  margin: 0;
  @media (min-width: 768px) {
    width: 768px;
    margin: 0 auto;
  }
  @media (min-width: 1100px) {
    min-width: 894px;
    margin: 0 auto;
  }
`;

const Heading = styled.p`
  padding: 10px 20px 0px 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  // text-decoration: underline;
  line-height: 28px;
  letter-spacing: 0.44px;
  color: #474747;
  text-align: left;
  @media (min-width: 768px) {
    min-width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 40px 0px;
  }
  @media (min-width: 1100px) {
    min-width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 40px 0px;
  }
`;

const Logo = styled.img`
  height: 32px;
  width: 176px;
  cursor: pointer;
  @media (min-width: 1100px) {
    height: 39px;
    width: 215px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media (min-width: 450px) {
    justify-content: center;
  }
`;

const InnerLogo = styled.div`
  @media (max-width: 450px) {
    margin-left: 16px;
  }
`;

const Image = styled.img`
  background-size: contain;
  width: 100%;
  @media (min-width: 768px) {
    width: 768px;
  }
  @media (min-width: 1100px) {
    width: 894px;
  }
`;

export { Container, Paragraph, Image, Heading, Logo, LogoContainer, InnerLogo };
