/* eslint-disable */
import axios from 'axios';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Context } from '../../context';
import {
  DesktopHeader,
  MobileTabHeader,
  Logo,
  Container,
  HeaderSection,
  HeaderText,
} from './ReroutePageLayout.styles';

export const ReroutePageLayout: FC = () => {
  const history = useHistory();
  const { tokenStorage, setTokens, setStatus } = React.useContext(Context);

  const searchParam = new URLSearchParams(window.location.search);

  React.useEffect(() => {
    const accessToken = searchParam.get('jwt');

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .get('/api/is_authenticated/', config)
      .then(() => {
        setTokens(accessToken, tokenStorage);
        setStatus('true', tokenStorage);
        history.push('/');
      })
      .catch((err) => {
        if (err.response.status === 403) alert('Invalid Link');
        history.push('/');
      });
  }, []);

  return (
    <>
      <DesktopHeader>
        <Logo src="/assets/logos/logo_white.svg" />
      </DesktopHeader>
      <MobileTabHeader>
        <Logo data-cy="logo" src="/assets/logos/logo_white.svg" />
        <div />
      </MobileTabHeader>
      <Container>
        <HeaderSection>
          <HeaderText>Signing In...</HeaderText>
        </HeaderSection>
      </Container>
    </>
  );
};
